import {action, observable} from 'mobx';
import {Intent, Skill, SlotForm} from '../model/Domain';
import uuid from 'uuid';

export class SlotModalStore {
    @observable
    slotForms: SlotForm[] = [];

    @observable
    intent?: Intent | null = null;

    @observable
    skill?: Skill | null = null;

    @observable
    openModal: boolean = false;

    @observable
    validations: SlotValidation[] = [];

    @observable
    parameterList: string[] = [];

    @action
    setValidations(validations: SlotValidation[]) {
        this.validations = validations;
    }

    @action
    setSlotForms(slotForms: SlotForm[]) {
        this.slotForms= []
        this.parameterList = []

        slotForms.forEach((slotForm) => {
            this.slotForms.push(slotForm)
            this.parameterList.push(slotForm.parameter)
        })
    }

    @action
    updateSlotForm(slotForm: SlotForm, index: number) {
        this.slotForms = this.slotForms.map((el, i) => {
            if (i === index) {
                this.parameterList[i] = slotForm.parameter
                return slotForm;
            }
            else {
                return el;
            }
        });
    }

    @action
    addSlotForm() {
        this.slotForms = [...this.slotForms, emptySlot];
        this.parameterList = [...this.parameterList, ""];
    }

    @action
    deleteSlotForm(index: number) {
        this.slotForms = this.slotForms.filter((_, i) => i !== index);
        this.parameterList = this.parameterList.filter((_, i) => i !== index);
    }

    @action
    open(skill: Skill, intent: Intent) {
        this.intent = intent;
        this.skill = skill;
        this.openModal = true;
        this.setSlotForms(intent.slot_info || [emptySlot]);
    }

    @action
    close() {
        this.intent = null;
        this.skill = null;
        this.openModal = false;
        this.slotForms = [];
        this.parameterList = [];
    }
}

const emptySlot = {
    key: uuid.v4(),
    required: false,
    is_list: false,
    parameter: '',
    entity_tags: [],
    entity_value_selection: 'keyword',
    default: ''
};

type SlotValidation = {
    parameter: boolean;
    entity: boolean;
};