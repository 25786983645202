import React from "react";
import {RouteProps, Route} from "react-router-dom";
import {observer} from "mobx-react-lite";
import {useStores} from "../hooks/useStores";
// import {useErrorBoundary} from "../hooks/useErrorBoundary";


const PrivateRoute: React.FC<RouteProps> = observer(({children, ...routeProps}) => {
    const {authenticationStore} = useStores();
    // const location = useLocation();
    // const errorBoundary = useErrorBoundary();


    if (authenticationStore.isLogin && authenticationStore.state === 'done') {
        return (
            <Route {...routeProps}>
                {children}
            </Route>
        );
    }
    else {
        return null;
    }
});

export default PrivateRoute;