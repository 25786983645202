import { action, observable } from "mobx";
import { emptyPage, Page } from "../model/Page";
import { EntityName } from "../model/Entity";
import { StoreState } from "../model/StoreState";
import * as entityApis from "../apis/entityApis";

export type EntityTypes = "normal-entity" | "post-entity" | "ignore-entity"

class EntityNameListStore {
    @observable
    data: Page<EntityName> = emptyPage();

    @observable
    keyword?: string;

    @observable
    state: StoreState = "none";

    @observable
    isSortedByEntityType: boolean = true;

    @observable
    showingEntityTypes: EntityTypes[] = ["normal-entity", "post-entity", "ignore-entity"];

    @action
    async load(agentId: number,
               domainId: number,
               page: number = 0,
               size: number = 15,
               keyword?: string
    ) {
        this.state = "pending";
        this.keyword = keyword;
        try {
            const { data } = await entityApis.findEntityNames(
                agentId,
                domainId,
                page,
                size,
                keyword,
                this.isSortedByEntityType,
                this.showingEntityTypes.join(",")
            )
            this.data = data.data;
            this.state = "done";
        } catch (e) {
            this.state = "error";
            throw e;
        }
    }

    @action
    notShowType = (typeNotToShow : EntityTypes) => {
        this.showingEntityTypes = this.showingEntityTypes.filter(type => type !== typeNotToShow)
    }

    @action
    showType = (typeToShow : EntityTypes) => {
        if (!this.showingEntityTypes.find(type => type === typeToShow)) {
            this.showingEntityTypes = [...this.showingEntityTypes, typeToShow]
        }
    }


    @action
    setIsSortedByEntityType = (isSort: boolean) => {
        this.isSortedByEntityType = isSort;
    }

    @action
    clear() {
        this.data = emptyPage();
        this.keyword = undefined;
        this.state = "none";
    }
}

export default EntityNameListStore;
