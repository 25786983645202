import axios from "axios";
import { API_HOST } from "../variables";

export const findSentences=(agentId: number, intentId: number) => {
    return axios.get(`${API_HOST}/v1/sentence-clustering/${intentId}`, {
        params: {agent_id: String(agentId)}
    });
}

export const findClustering=(agentId: number) => {
    return axios.get(`${API_HOST}/v1/sentence-clustering`, {
        params: {agent_id: String(agentId)}
    });
}

export const findTableClustering=(agentId: number) => {
    return axios.get(`${API_HOST}/v1/sentence-clustering/table`, {
        params: {agent_id: String(agentId)}
    });
}

export const checkClusteringState=(agentId: number) => {
    return axios.get(`${API_HOST}/v1/sentence-clustering/status`, {
        params: {agent_id: String(agentId)}
    });
}

export const bulkUpload=(agentId: number, file: any) => {
    const form = new FormData();
    form.append('agent_id', String(agentId));
    form.append('file', file);
    return axios.post(`${API_HOST}/v1/sentence-clustering`, form)
}

export const bulkDateTraining=(agentId: number, startDate: string, endDate: string) => {
    return axios.post(`${API_HOST}/v1/sentence-clustering/date`, {
        agent_id: agentId,
        start_date: startDate,
        end_date: endDate
    })
}