import { action, observable, runInAction } from "mobx";
import { StoreState } from "../model/StoreState";
import { AgentForm } from "../model/Agent";
import * as agentApis from "../apis/agentApis";

class AgentRegistrationStore {
    @observable
    state: StoreState = "none";

    @observable
    errorMessage: string = "";

    @action
    async create(form: AgentForm) {
        this.state = "pending";
        try {
            const response = await agentApis.createAgent(form);
            if (response.status === 200 && response.data.data) {
                runInAction(() => {
                    this.state = "done";
                    this.errorMessage = "";
                });
            } else {
                runInAction(() => {
                    this.state = "error";
                    this.errorMessage = response.data.msg;
                });
            }
            return response.data.data;
        } catch (e) {
            const _e = e as any;
            runInAction(() => {
                this.state = "error";
                this.errorMessage = _e.response.data.detail || "Error!!";
            });
            throw e;
        }
    }
}

export default AgentRegistrationStore;
