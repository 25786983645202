import React, { KeyboardEvent, useCallback, useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "../../hooks/useStores";
import { useIntl } from "react-intl";
import DictType from "../../model/DictType";
import { getTestSuggestions } from "../../apis/conversationApis";
import { Form, InputGroup } from "react-bootstrap";
import { JSONViewer } from "../../hooks/useJSONViewer";
import { Color } from "../StyleConstant";
import { isSpaceSymbolString } from "../Regex";
import { ConversationStyle, TestPanelStyle, TestWrapperStyle, TestPanelHeaderStyle } from "./ConversationStyleContainer";
import { SearchWordHighlighting } from "../TextHighlighting";

const AutocompleteTestPanel: React.FC<AutocompleteTestPanelProps> = observer(({agentId}) => {

    const { autocompleteTestStore } = useStores();

    const onClickCloseBtn = (e: React.MouseEvent) => {
        e.preventDefault();
        autocompleteTestStore.close();
    };

    return (
        <TestPanelStyle isOpen={autocompleteTestStore.isOpen}>
            <TestWrapperStyle isOpen={autocompleteTestStore.isOpen}>
                <TestPanelHeaderStyle color={Color.info}>
                    <h5 className="m-0">
                        <i className="dripicons-message mr-1" />
                        Get Your Suggestions
                    </h5>
                    <button type="button" className="close text-white" onClick={onClickCloseBtn}>
                        <span>×</span>
                    </button>
                </TestPanelHeaderStyle>

                <ConversationStyle>
                    <SearchSuggestionForm />
                </ConversationStyle>
            </TestWrapperStyle>

            <JSONViewer />
        </TestPanelStyle>
    )
});

export const SearchSuggestionForm: React.FC = observer(() => {
    const { autocompleteTestStore, autocompleteStore } = useStores();
    const intl = useIntl();

    const queryRef = useRef<HTMLInputElement>(null);
    const suggestionTimer = useRef<any>(null);

    const [invalidForm, setInvalidForm] = useState<DictType>({});
    const [showClearBtn, setShowClearBtn] = useState<boolean>(false);

    const [typing, setTyping] = useState<string>("");
    const [cursor, setCursor] = useState<number>(-1);
    const [isCompositionEnd, setIsCompositionEnd] = useState<boolean>(false);
    const [isProperCompositionUpdating, setIsProperCompositionUpdating] = useState<boolean>(true);
    const [isComposing, setIsComposing] = useState<boolean>(false);

    useEffect(() => {
        if (!autocompleteTestStore.isOpen) {
            setInvalidForm({});
            clearQueryInput();
            setCursor(-1);
        }
    }, [autocompleteTestStore.isOpen]);

    const getSuggestionClassName = useCallback((index: number) => {
        if (cursor === index) {
            return "py-1 bg-secondary text-white";
        } else {
            return "py-1 text-dark rounded";
        }
    }, [cursor]);

    const fetchSuggestions = () => {
        setIsComposing(false);
        setTyping(queryRef.current!.value);

        if (suggestionTimer.current) {
            clearTimeout(suggestionTimer.current);
        }

        if(isSpaceSymbolString(queryRef.current!.value)) {
            autocompleteTestStore.clearSuggestions();
            setCursor(-1);
            return
        }

        suggestionTimer.current = setTimeout(() => {
            setShowClearBtn(!!queryRef.current!.value);
            if (queryRef.current!.value) {
                getTestSuggestions(autocompleteStore.holder!.token, queryRef.current!.value)
                    .then(res => {
                        autocompleteTestStore.suggestions = res.data.filter((suggestion: any, index: number) => index < 10);
                        setCursor(-1);
                    })
                    .catch(e => console.error(e));
            } else {
                autocompleteTestStore.clearSuggestions();
                setCursor(-1);
            }
        }, 300)
    }

    const onChangeSearchInput = (e: React.FormEvent) => {
        e.preventDefault();

        const checkKoreanInputValidation = () => {
            if (!isProperCompositionUpdating) {
                setIsProperCompositionUpdating(true);
                return false;
            }
            if (isCompositionEnd) {
                setIsCompositionEnd(false);
                return false;
            }
            return true;
        }

        if (!checkKoreanInputValidation()) {
            return;
        }

        fetchSuggestions();
    };

    const onKeyUpInput = (e: KeyboardEvent) => {
        e.preventDefault();
        e.stopPropagation();

        if (isComposing) {
            if (isCompositionEnd) {
                setIsCompositionEnd(false)
                fetchSuggestions();
                return;
            }
        }
    };

    const onKeyDownInput = (e: KeyboardEvent) => {
        if (e.key === "Unidentified") {
            setIsComposing(true);
            return;
        }
        if (autocompleteTestStore.suggestions.length > 0) {
            if (e.key === "ArrowUp") {
                e.preventDefault();
                e.stopPropagation();
                // Arrow Up
                let nextCursor = cursor - 1;
                if (nextCursor < -1) {
                    nextCursor = autocompleteTestStore.suggestions.length - 1;
                }
                queryRef.current!.value = nextCursor !== -1 ? autocompleteTestStore.suggestions[nextCursor] : typing
                setCursor(nextCursor);
            } else if (e.key === "ArrowDown") {
                e.preventDefault();
                e.stopPropagation();
                // Arrow Down
                let nextCursor = cursor + 1;
                if (nextCursor > autocompleteTestStore.suggestions.length - 1) {
                    nextCursor = -1;
                }
                queryRef.current!.value = nextCursor !== -1 ? autocompleteTestStore.suggestions[nextCursor] : typing
                setCursor(nextCursor);
            }
        }
    };

    const onCompositionUpdate = (e: any) => {
        e.preventDefault()
        if (!e.data) {
            setIsProperCompositionUpdating(false);
        }
    }

    const clearQueryInput = () => {
        setShowClearBtn(false);
        setTyping("")
        queryRef.current!.value = '';
    }

    const onClickClearQueryBtn = (e: React.MouseEvent) => {
        e.preventDefault();
        autocompleteTestStore.clearSuggestions();
        setCursor(-1);
        clearQueryInput();
    }

    const onMouseOverSuggestion = (e: React.MouseEvent, index: number) => {
        e.preventDefault();
        setCursor(index);
    }

    const onClickSuggestion = (e: React.MouseEvent) => {
        e.preventDefault();
        queryRef.current!.value = autocompleteTestStore.suggestions[cursor]
        fetchSuggestions();
    }

    const onSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        fetchSuggestions();
    }

    return (
        <div>
            <Form onSubmit={onSubmit} autoComplete="off">
                <Form.Group controlId={'query-control'}>
                    <InputGroup>
                        <Form.Control type={'text'}
                                      placeholder={intl.formatMessage({id: 'i000237'})}
                                      className="is-invalid-no-bg search-input"
                                      onKeyUp={onKeyUpInput}
                                      onKeyDown={onKeyDownInput}
                                      onChange={onChangeSearchInput}
                                      onCompositionUpdate={onCompositionUpdate}
                                      onCompositionEnd={() => {setIsCompositionEnd(true)}}
                                      ref={queryRef}
                                      isInvalid={invalidForm.query} />
                        {showClearBtn && <span className="search-input-clear" style={{right: 10}}
                                               onClick={onClickClearQueryBtn}><i className="dripicons-cross" /></span>}
                    </InputGroup>
                </Form.Group>
            </Form>
            <div className="position-absolute bg-white pr-3 w-100"
                 style={{ zIndex: 100 }}>
                {queryRef.current?.value &&
                    autocompleteTestStore.suggestions.map((suggestion, index) =>
                    <div className={getSuggestionClassName(index)}
                         style={{cursor: "pointer"}}
                         onMouseOver={(e) => {onMouseOverSuggestion(e, index)}}
                         onClick={onClickSuggestion}>
                        {SearchWordHighlighting(suggestion, typing)}
                    </div>
                )}
            </div>
            <div className="mb-1">{intl.formatMessage({id: 'i000295'})}</div>
        </div>
    );
});


type AutocompleteTestPanelProps = {
    agentId: number;
};

export default AutocompleteTestPanel
