export const API_HOST = process.env.REACT_APP_API_HOST;
export const SOCKET_HOST = process.env.REACT_APP_SOCKET_HOST;
export const SOCKET_PATH = process.env.REACT_APP_SOCKET_PATH;

export const KEEPER_HOST = process.env.REACT_APP_KEEPER_HOST;
export const KEEPER_API_HOST = process.env.REACT_APP_KEEPER_API_HOST;
export const KEEPER_CLIENT_ID = process.env.REACT_APP_KEEPER_CLIENT_ID;
export const KEEPER_ENVIRONMENT = process.env.REACT_APP_KEEPER_ENVIRONMENT;

export const NLU_CTX = process.env.REACT_APP_NLU_CTX;
export const THEME = process.env.REACT_APP_THEME;

export const DISABLE_BUGSNAG = process.env.REACT_APP_DISABLE_BUGSNAG === "true";
