import axios from "axios";
import {Moment} from "moment";
import { API_HOST } from "../variables";
import {getUtcZeroTime} from "../util/dateUtil";

export const findStatCounts = (agentId: number, startDate: Moment, endDate: Moment) => {
    const start = startDate && getUtcZeroTime(startDate, "YYYY-MM-DD HH:mm:ss");
    const end = endDate && getUtcZeroTime(endDate, "YYYY-MM-DD HH:mm:ss");
    return axios.get(`${API_HOST}/v1/agents/${agentId}/statistics/counts`, {
        params: {
            start: start,
            end: end
        }
    });
};

export const findUserSentences = (agentId: number, startDate: Moment, endDate: Moment, intentId?: number) => {
    const start = startDate && getUtcZeroTime(startDate, "YYYY-MM-DD HH:mm:ss");
    const end = endDate && getUtcZeroTime(endDate, "YYYY-MM-DD HH:mm:ss");
    return axios.get(`${API_HOST}/v1/agents/${agentId}/statistics/sentences`, {
        params: {
            intent_id: intentId,
            started: start,
            ended: end
        }
    });
};
