import React, { useEffect } from "react";
import { Route, Switch, useParams, useLocation } from "react-router-dom";
import * as Layout from "@42maru/web-starter";
import { useStores } from "./hooks/useStores";
import { useErrorBoundary } from "./hooks/useErrorBoundary";
import Menu from "./component/layout/Menu";
import { switchContent } from "./routers";
import * as view from "./view";
import PrivateRoute from "./component/PrivateRoute";
import SingleTestPanel from "./component/conversation/SingleTestPanel";
import AutocompleteTestPanel from "./component/conversation/AutocompleteTestPanel";
import Footer from "./component/layout/Footer";
import { animateScroll } from "react-scroll";
import { API_HOST } from "./variables";
import ImportStatusPane from "./component/BackgroundJobPanel";

const AgentContextApp: React.FC = () => {
    const { authenticationStore, recentAgentsStore, singleTestPanelStore, autocompleteTestStore } = useStores();
    const { agentId } = useParams();
    const { pathname } = useLocation();
    const errorBoundary = useErrorBoundary();

    useEffect(() => {
        authenticationStore
            .loadLoginStatus()
            .then(() => {
                if (!authenticationStore.isLogin) {
                    window.location.href = `${API_HOST}/v1/auth/login?client_redirect_uri=${document.URL}`;
                }
            })
            .catch((error) => {
                window.location.href = `${API_HOST}/v1/auth/login?client_redirect_uri=${document.URL}`;
                console.error(error);
            });
    }, [authenticationStore]);

    useEffect(() => {
        animateScroll.scrollToTop({ duration: 0 });
    }, [agentId]);

    useEffect(() => {
        recentAgentsStore.load();

        // authenticationStore
        //     .checkPermission(+agentId)
        //     .then(() => {
        //         if (authenticationStore.allowed) {
        //             recentAgentsStore.setAgent(+agentId);
        //         } else {
        //             errorBoundary.setStatus(403);
        //         }
        //     })
        //     .catch((error) => {
        //         console.error(error);
        //         errorBoundary.setStatus(403);
        //     });
    }, [agentId, authenticationStore, errorBoundary, recentAgentsStore]);

    useEffect(() => {
        return () => {
            singleTestPanelStore.close();
        };
    }, [agentId, singleTestPanelStore]);

    useEffect(() => {
        return () => {
            autocompleteTestStore.close();
        };
    }, [pathname, autocompleteTestStore]);

    return (
        <Layout.Wrapper>
            <Menu />
            <ImportStatusPane />
            <Layout.ContentPage className="d-flex">
                <Layout.Content className="flex-grow-1">
                    <Layout.ContentContainer>
                        <Switch>
                            {switchContent
                                .filter((route) => route.hasMenu)
                                .map((route, index) => (
                                    <PrivateRoute key={`menu-view-route-${index}`} exact={route.exact} path={route.path}>
                                        <route.component />
                                    </PrivateRoute>
                                ))}
                            <Route path={"*"}>
                                <view.notFoundRedirectView />
                            </Route>
                        </Switch>
                    </Layout.ContentContainer>
                </Layout.Content>
                <SingleTestPanel agentId={+agentId} />
                <AutocompleteTestPanel agentId={+agentId} />

                <Footer />
            </Layout.ContentPage>
        </Layout.Wrapper>
    );
};

export default AgentContextApp;
