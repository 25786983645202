import {action, observable} from "mobx";
import {Entity} from "../model/Entity";


class EntityTempStore {
    @observable
    entity?: Entity;

    @action
    setEntity(entity?: Entity) {
        this.entity = entity;
    }
}

export default EntityTempStore;