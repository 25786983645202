import { action, observable } from "mobx";
import { StoreState } from "../model/StoreState";
import { AgentForm, ActiveForm } from "../model/Agent";
import * as agentApis from "../apis/agentApis";

class SettingStore {
    @observable
    domainNames: string[] = [];

    @observable
    updateState: StoreState = "none";

    @observable
    errorMessage: string = "";

    @action
    async updateAgent(agentId: number, form: AgentForm | ActiveForm) {
        this.updateState = "pending";
        try {
            const response = await agentApis.updateAgent(agentId, form);
            if (response.status === 200 && response.data.data) {
                this.updateState = "done";
                this.errorMessage = "";
            } else {
                this.updateState = "error";
                this.errorMessage = response.data.msg;
            }
        } catch (e) {
            this.updateState = "error";
            this.errorMessage = e.response.data.detail || "Error!!";
            throw e;
        }
    }
}

export default SettingStore;
