import styled from "styled-components";
import { Color } from "../StyleConstant";

type TestPanelStyleProps = {
    isOpen: boolean;
};

export const TestPanelStyle = styled.div<TestPanelStyleProps>`
  flex-grow: 0;
  flex-shrink: 0;
  margin: 0;
  width: 450px;
  transition: all .2s ease-out;
  // box-shadow: 0 0 24px 0 rgba(49,58,70,.1), 0 1px 0 0 rgba(49,58,70,.08);

  ${props => !props.isOpen && `
        width: 0;
    `}
`;

type TestWrapperStyleProps = {
    isOpen: boolean;
};

export const TestWrapperStyle = styled.div<TestWrapperStyleProps>`
  padding: 0;
  position: fixed;
  bottom: 0;
  top: 0;
  right: 0;
  height: 100vh;
  width: 450px;
  background-color: white;
  transition: all .2s ease-out;
  box-shadow: 0 0 24px 0 rgba(49,58,70,.1), 0 1px 0 0 rgba(49,58,70,.08);

  ${props => !props.isOpen && `
        right: -450px;
    `}
`;

type TestPanelHeaderStyleProps = {
    color: Color;
};

export const TestPanelHeaderStyle = styled.div<TestPanelHeaderStyleProps>`
  ${props => `background-color: ${props.color};`};
  
  padding: 1rem;
  display: flex;
  color: white;

  -webkit-box-pack: justify !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;

  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
`;


export const ConversationStyle = styled.div`
  padding: 0.75rem;
  background-color: white;
  height: calc(100vh - 53px);
  overflow-y: auto;
`
