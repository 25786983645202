import { action, observable } from "mobx";
import { ManagerOptions, SocketOptions } from "socket.io-client";
import { Account } from "../model/account";
import { NLUSocket } from "../util/NLUSocket";
import { getClientId } from "../util/stringUtil";
import { SOCKET_HOST, SOCKET_PATH } from "../variables";


type BackgroundJobType = "agent" | "intent" | "typo" |
    "entity" | "synonym" | "selector" | "autocomplete" |
    "testing" | "training" | "deploy" | "entity_delete"|
    "entity_bulk_insert" | "entity_bulk_apply";

export type WebSocketBackgroundJobStatus = {
    id?: string;
    type: BackgroundJobType;
    status: "in_progress" | "canceled" | "error" | "done" | "waiting";
    status_detail: string;
    count_total: number;
    count_current: number;
    elapsed_seconds: number;
    remain_seconds: number;
    processing_seconds: number;
    download_link?: string;
    created_at: string;
};

export type WebSocketBackgroundJobEnded = {
    type: BackgroundJobType;
    status: "error" | "done";
}

export interface WebSocketClient {
    client_id: string;
    user_id: number;
    user_name: string;
    user_email: string;
}

type WebScoketEvents = "background_job_status" | "background_job_ended" | "agent_summary";

interface EventFunctions {
    [key: string]: any[];
}
class WebSocketStore {
    static _instance: WebSocketStore;
    @observable agentId: number = -1;
    @observable connected: boolean = false;

    socketInitialized: boolean = false;
    socket?: NLUSocket;
    eventFunctions: EventFunctions = {
        background_job_status: [],
        background_job_ended: [],
        agent_summary: []
    };

    static getInstance = () => {
        if (WebSocketStore._instance == null) {
            WebSocketStore._instance = new WebSocketStore();
        }
        return WebSocketStore._instance;
    };

    @action
    connectSocket = (agentId: number, loginUser: Account, opts?: Partial<ManagerOptions & SocketOptions>) => {
        this.agentId = agentId;
        if (this.socket) {
            this.socket.disconnect();
            this.socket = undefined;
        }

        if (!this.socket) {
            this.socket = new NLUSocket(`${SOCKET_HOST}`, `${SOCKET_PATH}`, opts);
            this.socket.connect(
                {
                    client_id: getClientId(),
                    agent_id: agentId,
                    email: loginUser.email,
                    name: loginUser.name,
                    uid: loginUser.user_id
                },
                () => {
                    this.connected = true;
                }
            );

            this.socket.on("disconnected", () => {
                this.connected = false;
            });

            this.socket.on("background_job_status", (response: any) => {
                this.eventFunctions["background_job_status"].forEach((func) => func(response.data));
            });

            this.socket.on("background_job_ended", (response: any) => {
                this.eventFunctions["background_job_ended"].forEach((func) => func(response.data));
            });

            this.socket.on("agent_summary", (response: any) => {
                this.eventFunctions["agent_summary"].forEach((func) => func(response.data));
            });

            this.socketInitialized = true;
        }
    };

    sendCancelRequest = (job: WebSocketBackgroundJobStatus) => {
        if (this.socket) {
            this.socket.emit("request_background_job_status", { agent_id: this.agentId, job: job, method: "cancel" });
        }
    };

    sendDeleteRequest = (job: WebSocketBackgroundJobStatus) => {
        if (this.socket) {
            this.socket.emit("request_background_job_status", { agent_id: this.agentId, job: job, method: "delete" });
        }
    };

    send = (event: string, data: any) => {
        if (this.socket) {
            this.socket.emit(event, data);
        }
    };

    disconnect = () => {
        try {
            this.socket!.disconnect();
        } catch (e) {}
        this.connected = false;
    };

    addEventListener = (event: WebScoketEvents, callback: (data: any) => void) => {
        this.eventFunctions[event].push(callback);
    };

    removeEventListener = (event: WebScoketEvents, callback: (data: any) => void) => {
        const index = this.eventFunctions[event].indexOf(callback);
        if (index >= 0) {
            this.eventFunctions[event].splice(index, 1);
        }
    };

    @action
    clear = () => {
        this.agentId = -1;
        this.disconnect();
        this.socket = undefined;
        this.socketInitialized = false;
    };
}

export default WebSocketStore;
