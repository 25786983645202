import { action, observable, runInAction } from "mobx";
import * as agentApis from "../apis/agentApis";

class RecentAgentsStore {
    @observable
    data: number[] = [];

    @observable
    permissions: string[] = [];

    isPermissionLoaded: boolean = false;

    @action
    load() {
        const recentAgents = localStorage.getItem("recentAgents");
        if (recentAgents) {
            this.data = JSON.parse(recentAgents);
        }
    }

    @action
    setAgent(agentId: number) {
        const filteredAgents = this.data.filter((el) => el !== agentId);
        this.data = [agentId, ...filteredAgents.slice(0, 5)];
        localStorage.setItem("recentAgents", JSON.stringify(this.data));
        this.isPermissionLoaded = false;
    }

    loadPermissions(agentId: number) {
        agentApis
            .getPermission(agentId)
            .then((response) => {
                runInAction(() => {
                    if (Array.isArray(response.data.data)) {
                        this.permissions = response.data.data;
                    } else {
                        this.permissions = ["view:*"];
                    }
                    this.isPermissionLoaded = true;
                });
            })
            .catch(() => {
                runInAction(() => {
                    this.permissions = [];
                    this.isPermissionLoaded = true;
                });
            });
    }
}

export default RecentAgentsStore;
