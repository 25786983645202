import { action, observable } from "mobx";
import { emptyPage, Page } from "../model/Page";
import { DeployHistory } from "../model/DeployHistory";
import { StoreState } from "../model/StoreState";
import * as agentApis from "../apis/agentApis";

class DeployHistoryStore {
    @observable
    data: Page<DeployHistory> = emptyPage();

    @observable
    state: StoreState = "none";

    @action
    async load(agentId: number, page: number, size: number) {
        this.state = "pending";
        try {
            const { data } = await agentApis.findAgentDeployHistory(agentId, page, size);
            this.data = data.data;
            this.state = "done";
        } catch (e) {
            this.state = "error";
            throw e;
        }
    }

    @action
    clear() {
        this.data = emptyPage();
        this.state = "none";
    }
}

export default DeployHistoryStore;
