import React from 'react';
import {Button, Modal} from 'react-bootstrap';
import {observer} from "mobx-react-lite";
import {useDialog} from "../../hooks/useDialog";
import {useIntl} from "react-intl";

const Dialog: React.FC = observer(() => {

    const dialog = useDialog();
    const intl = useIntl();

    const handleClose = () => {
        if (dialog.onCancel) {
            dialog.onCancel();
        }

        dialog.close();
    };

    const onClickConfirmBtn = (e: React.MouseEvent) => {
        e.preventDefault();

        if (dialog.onConfirm) {
            dialog.onConfirm();
        }

        dialog.close();
    };

    return (
        <Modal show={dialog.isOpen} onHide={handleClose} animation={true}>
            <Modal.Header className={`modal-colored-header bg-${dialog.variant}`} closeButton>
                <Modal.Title as={'h4'}>{dialog.title || 'Dialog'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>{dialog.content}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant={dialog.variant} onClick={onClickConfirmBtn}>{dialog.confirmText || intl.formatMessage({id: 'i000253'})}</Button>
                <Button variant={'outline-secondary'} onClick={handleClose}>{dialog.cancelText || intl.formatMessage({id: 'i000025'})}</Button>
            </Modal.Footer>
        </Modal>
    );
});

export default Dialog;