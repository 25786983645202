import { action, computed, observable } from "mobx";
import { emptyPage, Page } from "../model/Page";
import { CorrectionKeyword } from "../model/CorrectionKeyword";
import { StoreState } from "../model/StoreState";
import * as typoApis from "../apis/typoApis";

class CorrectionKeywordStore {
    @observable
    page: Page<CorrectionKeyword> = emptyPage();

    @observable
    correctionKeyword?: CorrectionKeyword;

    @observable
    checklist: boolean[] = [];

    @observable
    query?: string;

    @observable
    state: StoreState = "none";

    @observable
    unadaptedTypos: string = "";

    @action
    async findCorrectionKeywords(agentId: number, page: number, size: number, query?: string) {
        this.state = "pending";
        this.query = query;

        try {
            const { data } = await typoApis.findCorrectionKeywords(agentId, page, size, query);
            this.checklist = new Array(data.data.content.length).fill(false);
            data.data.content = data.data.content.map((correctionKeyword: CorrectionKeyword) => {
                correctionKeyword.typos = this.typoSort(correctionKeyword);
                return correctionKeyword;
            });
            this.page = data.data;
            this.state = "done";
        } catch (e) {
            this.state = "error";
            throw e;
        }
    }

    @action
    async createCorrectionKeyword(agentId: number, correctionKeyword: string, typoKeywords: string[]) {
        this.state = "pending";
        try {
            const { data, status } = await typoApis.createCorrectionKeyword(agentId, correctionKeyword, typoKeywords);
            if (status === 200) {
                this.state = "done";
                this.unadaptedTypos = data.msg;
            } else {
                this.state = "error";
            }
        } catch (e) {
            this.state = "error";
            throw e;
        }
    }

    @action
    async updateCorrectionKeyword(agentId: number, correctionKeywordId: string, correctionKeyword: string, typoKeywords: string[]) {
        this.state = "pending";
        try {
            const {data, status} = await typoApis.updateCorrectionKeyword(agentId, correctionKeywordId, correctionKeyword, typoKeywords);
            if (status === 200) {
                this.state = "done";
                this.unadaptedTypos = data.msg;
            } else {
                this.state = "error";
            }
        } catch (e) {
            this.state = "error";
            throw e;
        }
    }

    @action
    async uploadCorrectionKeywordFile(agentId: number, file: File, isCleanImport?: boolean) {
        this.state = "pending";
        try {
            const { data } = await typoApis.uploadCorrectionKeywordFile(agentId, file, isCleanImport);
            if (data.status === 200) {
                this.state = "done";
            } else {
                this.state = "error";
            }
        } catch (e) {
            this.state = "error";
            throw e;
        }
    }

    @action
    async deleteCorrectionKeywords(agentId: number, ids: string[]) {
        this.state = "pending";
        try {
            const response = await typoApis.deleteCorrectionKeyword(agentId, ids);
            if (response.status === 200) {
                this.state = "done";
                if (this.correctionKeyword && ids.includes(this.correctionKeyword.id)) {
                  this.correctionKeyword = undefined;
                };
            } else {
                this.state = "error";
            }
        } catch (e) {
            this.state = "error";
            throw e;
        }
    }

    @action
    setCorrectionKeyword(correctionKeyword?: CorrectionKeyword) {
        this.correctionKeyword = correctionKeyword;
    }

    @action
    toggleChecklist(index: number) {
        if (index >= this.checklist.length) return;
        this.checklist = this.checklist.map((check, i) => (i === index ? !check : check));
    }

    @action
    changeAllChecklist(checked: boolean) {
        this.checklist = new Array(this.checklist.length).fill(checked);
    }

    @action
    setUnadaptedTypos(query: string) {
        this.unadaptedTypos = query;
    }

    @action
    setQuery(query?: string) {
        this.query = query;
    }

    @computed
    get checkedCorrectionKeywordIds() {
        return this.page?.content.filter((_, index) => this.checklist[index]).map((item) => item.id) || [];
    }

    @action
    clear() {
        this.page = emptyPage();
        this.checklist = [];
        this.correctionKeyword = undefined;
        this.query = undefined;
        this.state = "none";
        this.unadaptedTypos = "";
    }

    @action
    typoSort(correctionKeyword: CorrectionKeyword) {
        const result: string[] = [correctionKeyword.keyword];

        return result.concat(correctionKeyword.typos?.filter((value) => value !== correctionKeyword.keyword).sort());
    }
}

export default CorrectionKeywordStore;
