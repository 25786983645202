import React, { useEffect, useMemo, useRef, useState } from "react";
import { Button, Card, Dropdown, Form, InputGroup, ListGroup, Spinner } from "react-bootstrap";
import { useNotification } from "../../hooks/useNotification";
import { useIntl } from "react-intl";
import "../../static/css/widget.scss";
import { useStores } from "../../hooks/useStores";
import { observer } from "mobx-react-lite";
import classNames from "classnames";
import { IntentAnswer } from "../../model/IntentPredictResult";
import { Link } from "react-router-dom";
import useJSONViewer, { JSONViewer } from "../../hooks/useJSONViewer";
import DictType from "../../model/DictType";
import { ConversationStyle, TestPanelStyle, TestWrapperStyle, TestPanelHeaderStyle } from "./ConversationStyleContainer";
import { Color } from "../StyleConstant";

const SingleTestPanel: React.FC<SingleTestPanelProps> = observer(({agentId}) => {

    const { singleTestPanelStore, agentStore, intentPredictStore } = useStores();

    const notification = useNotification();
    const intl = useIntl();
    const jsonViewer = useJSONViewer();

    useEffect(() => {
        intentPredictStore.clear();
    }, [agentId, intentPredictStore]);

    const entityReplace = useMemo(() => {
        if (intentPredictStore.result) {
            return intentPredictStore.result.ner.tagged_queries
                .replace(/{/gi, '<span class="badge badge-primary-lighten">')
                .replace(/}/gi, '</span>');
        }

        return '';
    }, [intentPredictStore.result]);

    const synonymReplace = useMemo(() => {
        if (intentPredictStore.result) {
            const query = intentPredictStore.result.synonym_result.query;
            const synonyms = intentPredictStore.result.synonym_result.synonyms;
            const fragments: string[] = [];
            synonyms.forEach((synonym, index) => {
                if (index === 0) {
                    fragments.push(query.slice(0, synonym.start_idx));
                }
                else {
                    const prevSynonym = synonyms[index - 1];
                    fragments.push(query.slice(prevSynonym.end_idx + 1, synonym.start_idx));
                }

                if (index === synonyms.length - 1) {
                    fragments.push(query.slice(synonym.end_idx + 1));
                }
            });

            if (synonyms.length > 0) {
                const taggedSynonym = fragments.reduce((acc, cur, index) => {
                    const keyword = synonyms.length > index ? `<span class="badge badge-danger-lighten">${synonyms[index].keyword}</span>` : '';
                    // const highlightKeyword = keyword ? `<span class="badge badge-danger-lighten">${keyword}</span>` : keyword;
                    return `${acc}${cur}${keyword}`;
                }, '');

                return taggedSynonym
                    .replace(/{/gi, '<span class="badge badge-primary-lighten">')
                    .replace(/}/gi, '</span>');
            }
            else {
                return intentPredictStore.result.synonym_result.changed_query
                    .replace(/{/gi, '<span class="badge badge-primary-lighten">')
                    .replace(/}/gi, '</span>');
            }

        }
        return '';
    }, [intentPredictStore.result]);

    const hiddenResult = useMemo(() => {
        return !intentPredictStore.result
    }, [intentPredictStore.result]);

    const hiddenAnalyzer = useMemo(() => {
        return intentPredictStore.state === 'pending' || !intentPredictStore.result || intentPredictStore.result.answers.length < 1
    }, [intentPredictStore.result, intentPredictStore.state]);

    const showNoAnswer = useMemo(() => {
        return intentPredictStore.state !== 'pending' && intentPredictStore.result && intentPredictStore.result.answers.length < 1;
    }, [intentPredictStore.result, intentPredictStore.state]);

    const predictIntent = (query: string) => {
        const agentToken = agentStore.agent?.token;
        if (agentToken) {
            intentPredictStore.predict(query, agentToken, true, '').then(() => {

            }).catch(error => {
                notification.error(error);
            });
        }
    }

    const onClickReloadBtn = (e: React.MouseEvent) => {
        e.preventDefault();
        if (intentPredictStore.result?.ner.query) {
            predictIntent(intentPredictStore.result.ner.query);
        }

    };

    const onClickResponseData = () => {
        if (intentPredictStore.result) {
            jsonViewer.open('Response', intentPredictStore.result);
        }
    }

    const onClickDebugData = () => {
        if (intentPredictStore.result) {
            jsonViewer.open('Response', intentPredictStore.result.debug_factor);
        }
    }

    const onClickCloseBtn = (e: React.MouseEvent) => {
        e.preventDefault();
        singleTestPanelStore.close();
    };

    return (
        <TestPanelStyle isOpen={singleTestPanelStore.isOpen}>
            <TestWrapperStyle isOpen={singleTestPanelStore.isOpen}>
                <TestPanelHeaderStyle color={Color.primary}>
                    <h5 className="m-0">
                        <i className="dripicons-message mr-1" />
                        Try it Out
                    </h5>
                    <button type="button" className="close text-white" onClick={onClickCloseBtn}>
                        <span>×</span>
                    </button>
                </TestPanelHeaderStyle>

                <ConversationStyle>
                    <SearchForm />

                    <Card border={'info'} className={classNames('border', {'d-none': hiddenResult})}>
                        <Card.Body className="p-2 d-flex flex-column">
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="d-flex flex-grow-1 align-items-center">
                                    <i className="dripicons-conversation mr-1 font-18" />
                                    <Card.Title className="text-break">
                                        {intentPredictStore.result?.ner.query}
                                    </Card.Title>
                                </div>
                                <Dropdown>
                                    <Dropdown.Toggle id="single-test-panel-more"
                                                     as={'button'}
                                                     className="btn btn-link d-block p-0 flex-grow-0"
                                                     bsPrefix={'unused'}>
                                        <i className="mdi mdi-dots-vertical" />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={onClickResponseData}>Response</Dropdown.Item>
                                        <Dropdown.Item onClick={onClickDebugData}>Debug</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                            <small className="text-right">
                                User said...
                                <Button variant="light" className="ml-1 p-0 badge badge-light border-0" onClick={onClickReloadBtn}>
                                    <div className="dripicons-retweet" style={{fontSize: "15px"}}/>
                                </Button>
                            </small>
                        </Card.Body>
                    </Card>

                    <Card border={'info'} className={classNames('border', {'d-none': hiddenAnalyzer})}>
                        <Card.Body className="p-2">
                            <Card.Title>Entity Replaced</Card.Title>
                            <p dangerouslySetInnerHTML={{__html: entityReplace}} />

                            <Card.Title>Synonym Replaced</Card.Title>
                            <p dangerouslySetInnerHTML={{__html: synonymReplace}} />

                            {(intentPredictStore.result && intentPredictStore.result.ner.entities.length > 0) && (
                                <>
                                    <Card.Title>Entities</Card.Title>
                                    <ListGroup>
                                        {intentPredictStore.result.ner.entities.map((entity, index) => (
                                            <ListGroup.Item key={index} className="d-flex justify-content-between align-items-center px-2 py-1">
                                                <strong className="font-12">@{entity.name}</strong>
                                                <span>{entity.value}</span>
                                            </ListGroup.Item>
                                        ))}
                                    </ListGroup>
                                </>
                            )}
                        </Card.Body>
                    </Card>

                    {intentPredictStore.result?.answers.map((answer, index) => (
                        <AnswerFragment key={answer.intent.intent_id}
                                        agentId={agentId}
                                        answer={answer}
                                        defaultOpen={index === 0}
                                        hidden={hiddenAnalyzer} />
                    ))}

                    {showNoAnswer && (
                        <Card border={'secondary'} className="border">
                            <Card.Body className="p-2">
                                <Card.Title className="text-muted">
                                    {intl.formatMessage({id: 'i000175'})}
                                </Card.Title>
                            </Card.Body>
                        </Card>
                    )}

                    {intentPredictStore.state === 'pending' && <Spinner animation={'border'} size={'sm'} />}
                </ConversationStyle>
            </TestWrapperStyle>

            <JSONViewer />
        </TestPanelStyle>
    )
});

const SearchForm: React.FC = observer(() => {
    const { intentPredictStore, agentStore, singleTestPanelStore } = useStores();
    const notification = useNotification();
    const intl = useIntl();

    const queryRef = useRef<HTMLInputElement>(null);

    const [invalidForm, setInvalidForm] = useState<DictType>({});
    const [showClearBtn, setShowClearBtn] = useState<boolean>(false);

    useEffect(() => {
        if (!singleTestPanelStore.isOpen) {
            setInvalidForm({});
            clearQueryInput();
        }
    }, [singleTestPanelStore.isOpen]);

    const predictIntent = (query: string) => {
        const agentToken = agentStore.agent?.token;
        if (agentToken) {
            intentPredictStore.predict(query, agentToken, true, '').then(() => {

            }).catch(error => {
                notification.error(error);
            });
        }
    }

    const clearQueryInput = () => {
        setShowClearBtn(false);
        if (queryRef.current) {
            queryRef.current.value = '';
        }
    }

    const onChangeSearchInput = (e: React.FormEvent) => {
        e.preventDefault();

        setShowClearBtn(!!queryRef.current?.value);
    }

    const onClickClearQueryBtn = (e: React.MouseEvent) => {
        e.preventDefault();
        clearQueryInput();
    }

    const onSubmit = (e: any) => {
        e.preventDefault();
        e.stopPropagation();

        const query = queryRef.current?.value;

        const validation = {query: !query};
        setInvalidForm(validation);

        if (Object.values(validation).some(value => value)) {
            return;
        }

        predictIntent(query!);
        clearQueryInput();
    };

    return (
        <Form onSubmit={onSubmit} autoComplete="off">
            <Form.Group controlId={'query-control'}>
                <InputGroup>
                    <Form.Control type={'text'}
                                  placeholder={intl.formatMessage({id: 'i000237'})}
                                  className="is-invalid-no-bg search-input"
                                  onChange={onChangeSearchInput}
                                  ref={queryRef}
                                  isInvalid={invalidForm.query} />
                    {showClearBtn && <span className="search-input-clear" onClick={onClickClearQueryBtn}><i className="dripicons-cross" /></span>}
                    <InputGroup.Append>
                        <Button type={'submit'} variant="outline-secondary">
                            <i className="dripicons-search"/>
                        </Button>
                    </InputGroup.Append>
                </InputGroup>
            </Form.Group>
        </Form>
    );
});

const AnswerFragment: React.FC<AnswerFragmentProps> = ({agentId, answer, defaultOpen, hidden}) => {

    const [open, setOpen] = useState(defaultOpen);
    const { skillStore, intentStore } = useStores();

    const slotKeys = useMemo(() => {
        return Object.keys(answer.slots);
    }, [answer]);

    const onClickOpenToggle = (e: React.MouseEvent) => {
        e.preventDefault();
        setOpen(!open);
    };

    return (
        <Card key={answer.intent.intent_id} border={'info'} className={classNames('border', {'d-none': hidden})}>
            <Card.Header className="d-flex justify-content-between align-items-center p-2">
                <strong>{answer.intent.intent_name}</strong>
                <div>
                    <span>{answer.intent.score}</span>
                    <button className="btn btn-link p-0 ml-2" onClick={onClickOpenToggle}>
                        <i className={classNames('mdi', {'mdi-chevron-up': open, 'mdi-chevron-down': !open})} />
                    </button>
                </div>
            </Card.Header>
            <Card.Body className={classNames('p-2', {'d-none': !open})}>
                <Card.Text className="text-muted mb-0">Skill</Card.Text>
                <Card.Text>{answer.intent.skill_name}</Card.Text>
                {answer.intent_answers.length > 0 && (
                    <>
                        <Card.Text className="text-muted mb-0">Answers</Card.Text>
                        {answer.intent_answers.map((intentAnswer, index) => (
                            <Card.Text key={index} className={classNames({'mb-0': index < answer.intent_answers.length - 1})}>{intentAnswer}</Card.Text>
                        ))}
                    </>
                )}
                {slotKeys.length > 0 && (
                    <div className="mb-2">
                        <div className="d-flex justify-content-between">
                            <Card.Text className="text-muted mb-0">Parameter</Card.Text>
                            <Card.Text className="text-muted mb-0">Value</Card.Text>
                        </div>
                        {slotKeys.map(slotKey => (
                            <div key={slotKey} className="d-flex justify-content-between">
                                <Card.Text className="mb-0">${slotKey}</Card.Text>
                                <SlotValueFragment slotKey={slotKey} slotValue={answer.slots[slotKey]} />
                            </div>
                        ))}
                    </div>
                )}
                <Link to={`/${agentId}/domain/${answer.intent.domain_id}/skill-intent`}
                      className="float-right"
                      onClick={() => {
                          skillStore.setSelectedSkillId(answer.intent.skill_id);
                          intentStore.setAnswerIntentId(answer.intent.intent_id);
                      }}
                >Visit Intent<i className="mdi mdi-arrow-right ml-1" /></Link>
            </Card.Body>
        </Card>
    );
};

const SlotValueFragment: React.FC<SlotValueFragmentProps> = ({slotKey, slotValue}) => {
    const jsonViewer = useJSONViewer();
    const intl = useIntl();

    const isObject = slotValue instanceof Object;

    const onClickDetailBtn = (e: React.MouseEvent) => {
        e.preventDefault();
        jsonViewer.open(`Slot Value: $${slotKey}`, slotValue);
    }

    if (isObject) {
        return (
            <Card.Text className="mb-0">
                <button className="btn btn-link p-0" onClick={onClickDetailBtn}>{intl.formatMessage({id: 'i000006'})}</button>
            </Card.Text>
        );
    }
    else {
        return (
            <Card.Text className="mb-0">{slotValue}</Card.Text>
        );
    }
};

type SingleTestPanelStyleProps = {
    isOpen: boolean;
};

type AnswerFragmentProps = {
    agentId: number;
    answer: IntentAnswer;
    defaultOpen: boolean;
    hidden: boolean;
};

type SlotValueFragmentProps = {
    slotKey: string;
    slotValue: any;
}

type SingleTestPanelProps = {
    agentId: number;
};

export default SingleTestPanel