import {action, observable} from "mobx";
import {SelectorSentence} from "../model/SelectorSentence";
import { SimpleIntent } from "../model/Domain";

export class SelectorStore {

    @observable
    selectorSentenceChecklist: boolean[] = [];

    @observable
    isOpenEditForm: boolean = false;

    @observable
    editingSelectorSentence?: SelectorSentence;

    @observable
    editingSelectorIntent?: SimpleIntent;

    @observable
    jobId?: string;

    @action
    checkSelectorSentence(index: number, checked: boolean) {
        if (this.selectorSentenceChecklist.length <= index) return;

        this.selectorSentenceChecklist = this.selectorSentenceChecklist.map((value, i) => i === index ? checked : value);
    }

    @action
    checkAllSelectorSentences(checked: boolean) {
        this.selectorSentenceChecklist.fill(checked);
    }

    @action
    openEditForm(selectorSentence: SelectorSentence) {
        this.isOpenEditForm = true;
        this.editingSelectorSentence = selectorSentence;
        this.editingSelectorIntent = {id: selectorSentence.intent_id, name: selectorSentence.intent_name};
    }

    @action
    closeEditForm() {
        this.isOpenEditForm = false;
        this.editingSelectorSentence = undefined;
        this.editingSelectorIntent = undefined;
    }

    @action
    setJobId(jobId: string) {
        this.jobId = jobId;
    }

    @action
    removeJobId() {
        this.jobId = undefined;
    }

    @action
    clear() {
        this.editingSelectorSentence = undefined;
        this.selectorSentenceChecklist = [];
        this.isOpenEditForm = false;
    }

}
