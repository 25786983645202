import React, { CSSProperties } from "react";

type styleObject = {
    [index: string]: CSSProperties
}

const highlightStyles: styleObject = {
    search: { fontWeight: "bold", color: "red" },
    darkBackground: {fontWeight: "bold", color: "springgreen"}
};

export const SearchWordHighlighting = (suggestion: string, searchWord?: string, styleKey?: string) => {
    if (!searchWord) {
        return suggestion
    }

    const highlightStyle = (styleKey && highlightStyles[styleKey]) ? highlightStyles[styleKey] : highlightStyles['search'];
    const highlightList: string[] = [];

    suggestion.split(searchWord).forEach((fraction, index) => {
        if (index > 0) {
            highlightList.push(searchWord);
        }
        highlightList.push(fraction)
    })

    return (<span>
            {
                highlightList.map((element, i) => {
                    return <span key={i} style={i % 2 === 1 ? highlightStyle : {}}>
                        {element}
                    </span>;
                })
            }
        </span>);
};