import { emptyPage, Page } from "../model/Page";
import { SelectorSentence } from "../model/SelectorSentence";
import { action, observable } from "mobx";
import { StoreState } from "../model/StoreState";
import * as selectorApis from "../apis/selectorApis";

class SelectorSentenceListStore {
    @observable
    data: Page<SelectorSentence> = emptyPage();

    @observable
    keyword?: string;

    @observable
    state: StoreState = "none";

    @action
    async load(agentId: number, page: number, size: number, keyword?: string) {
        this.state = "pending";
        this.keyword = keyword;
        try {
            const { data } = await selectorApis.findSelectorSentences(agentId, page, size, keyword);
            this.data = data.data;
            this.state = "done";
        } catch (e) {
            this.state = "error";
            throw e;
        }
    }

    @action
    clear() {
        this.data = emptyPage();
        this.keyword = undefined;
        this.state = "none";
    }
}

export default SelectorSentenceListStore;
