import { action, observable, runInAction } from "mobx";
import * as authenticationApis from "../apis/authenticationApis";
import { StoreState } from "../model/StoreState";
import {Account} from "../model/account";

class AuthenticationStore {
    @observable
    loginUser?: Account;
    @observable
    isLogin: boolean = false;

    @observable
    allowed?: boolean = undefined;

    @observable
    state: StoreState = "none";

    @action
    async loadLoginStatus() {
        try {
            const { data } = await authenticationApis.loadLoginStatus();
            runInAction(() => {
                this.loginUser = data.data;
                this.isLogin = !!data.data.user_id;
                this.state = "done";
            });
        } catch (e) {
            runInAction(() => {
                this.loginUser = undefined;
                this.isLogin = false;
                this.state = "error";
            });

            throw e;
        }
    }

    @action
    async checkPermission(agentId: number) {
        try {
            const { data } = await authenticationApis.checkPermission(agentId);
            runInAction(() => {
                this.allowed = data.status === 200;
            });
        } catch (e) {
            runInAction(() => {
                this.allowed = false;
            });
        }
    }
}

export default AuthenticationStore;
