import React, {useLayoutEffect} from "react";
import {action, observable} from "mobx";
import {observer} from "mobx-react-lite";
import {useLocation} from "react-router-dom";
import * as view from "../view";

class ErrorBoundaryStore {
    @observable
    status?: number;

    @action
    setStatus(status?: number) {
        this.status = status;
    }
}

const context = React.createContext(new ErrorBoundaryStore());
export const useErrorBoundary = () => React.useContext(context);


export const ErrorBoundary: React.FC = observer(({children}) => {
    const errorBoundary = useErrorBoundary();
    const location = useLocation();

    useLayoutEffect(() => {
        errorBoundary.setStatus(undefined);
    }, [errorBoundary, location.pathname])

    if (errorBoundary.status === 403) {
        return (
            <view.forbiddenView />
        );
    }
    else if (errorBoundary.status === 404) {
        return (
            <view.notFoundView />
        );
    }
    else {
        return (
            <>
                {children}
            </>
        );
    }
});
