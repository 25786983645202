import axios from "axios";
import { API_HOST } from "../variables";

export const findCorrectionKeywords = (agentId: number, page: number, size: number, query?: string) => {
    return axios.get(`${API_HOST}/v1/agents/${agentId}/typos`, {
        params: {
            page: page,
            size: size,
            keyword: query
        }
    });
};

export const createCorrectionKeyword = (agentId: number, correctionKeyword: string, typoKeywords: string[]) => {
    return axios.post(`${API_HOST}/v1/agents/${agentId}/typos`, {
        keyword: correctionKeyword,
        typos: typoKeywords
    });
};

export const updateCorrectionKeyword = (
    agentId: number,
    correctionKeywordId: string,
    correctionKeyword: string,
    typoKeywords: string[]
) => {
    return axios.put(`${API_HOST}/v1/agents/${agentId}/typos/${correctionKeywordId}`, {
        keyword: correctionKeyword,
        typos: typoKeywords
    });
};

export const uploadCorrectionKeywordFile = (agentId: number, file: File, isCleanImport?: boolean) => {
    const form = new FormData();
    const is_clean_import = isCleanImport ? '1' : '0'
    form.append("is_clean_import", is_clean_import)
    form.append("file", file);

    return axios.post(`${API_HOST}/v1/agents/${agentId}/typos/import`, form);
};

export const deleteCorrectionKeyword = (agentId: number, ids: string[]) => {
    const params = new URLSearchParams();
    ids.forEach((id) => {
        params.append("ids", `${id}`);
    });
    return axios.delete(`${API_HOST}/v1/agents/${agentId}/typos`, {
        params: params
    });
};
