import { action, observable } from "mobx";
import { DomainStatisticsDetail, IntentStatisticsDetail, StatCounts, UserSentenceStatistics } from "../model/statistics";
import { StoreState } from "../model/StoreState";
import * as statisticsApis from "../apis/statisticsApis";
import * as agentApis from "../apis/agentApis";
import {Moment} from "moment";

class StatisticsStore {
    @observable
    statCounts?: StatCounts;

    @observable
    statCountsDetails: DomainStatisticsDetail[] = [];

    @observable
    userSentencesMap: { [key: string]: UserSentenceStatistics[] } = {};

    @observable
    domainStatisticsDetail?: DomainStatisticsDetail;

    @observable
    intentStatisticsDetail?: IntentStatisticsDetail;

    @observable
    state: StoreState = "none";

    @action
    async findStatCounts(agentId: number, start: Moment, end: Moment) {
        this.clear();
        this.state = "pending";
        try {
            const statsResponse = await statisticsApis.findStatCounts(agentId, start, end);
            const data = statsResponse.data.data;
            this.statCounts = data;
            const response = await agentApis.findIntentIds(agentId);
            const idSet = response.data.data;

            const classifyStats: DomainStatisticsDetail[] = Object.entries<any>(data.domain)
                .filter(([_, domain]) => !!domain.id)
                .map(([domainName, domain]) => {
                    return {
                        id: domain.id,
                        name: domainName,
                        count: domain.cnt,
                        isNotExist: !idSet[domain.id],
                        skills: Object.entries<any>(data.skill)
                            .filter(([_, skill]) => domain.id === skill.domain_id)
                            .map(([skillName, skill]) => {
                                return {
                                    name: skillName,
                                    count: skill.cnt,
                                    isNotExist: !(idSet[domain.id] && idSet[domain.id][skill.id]),
                                    intents: Object.entries<any>(data.intent)
                                        .filter(([_, intent]) => skill.id === intent.skill_id)
                                        .map(([intentName, intent]) => {
                                            return {
                                                id: intent.id,
                                                name: intentName,
                                                count: intent.cnt,
                                                isNotExist: !(idSet[domain.id] && idSet[domain.id][skill.id] && idSet[domain.id][skill.id][intent.id]),
                                            };
                                        })
                                };
                            })
                    };
                });
            const noClassifyStat: DomainStatisticsDetail[] = Object.entries<any>(data.domain)
                .filter(([_, domain]) => !domain.id)
                .map(([domainName, domain]) => {
                    return {
                        name: domainName,
                        count: domain.cnt,
                        skills: Object.entries<any>(data.skill)
                            .filter(([_, skill]) => !skill.id)
                            .map(([skillName, skill]) => {
                                return {
                                    name: skillName,
                                    count: skill.cnt,
                                    intents: Object.entries<any>(data.intent)
                                        .filter(([_, intent]) => !intent.id)
                                        .map(([intentName, intent]) => {
                                            return {
                                                name: intentName,
                                                count: intent.cnt
                                            };
                                        })
                                };
                            })
                    };
                });

            this.statCountsDetails = [...classifyStats, ...noClassifyStat];
            this.domainStatisticsDetail = this.statCountsDetails.length > 0 ? this.statCountsDetails[0] : undefined;
            this.state = "done";
        } catch (e) {
            this.state = "error";
            throw e;
        }
    }

    @action
    async findUserSentences(agentId: number, start: Moment, end: Moment, intentId?: number) {
        this.state = "pending";
        try {
            const { data } = await statisticsApis.findUserSentences(agentId, start, end, intentId);
            this.userSentencesMap = {
                ...this.userSentencesMap,
                [`${intentId || "no-classification"}`]: data.data
            };
            this.state = "done";
        } catch (e) {
            this.state = "error";
            throw e;
        }
    }

    @action
    setDomainStatisticsDetail(domainStatisticsDetail: DomainStatisticsDetail) {
        this.domainStatisticsDetail = domainStatisticsDetail;
    }

    @action
    setIntentStatisticsDetail(intentStatisticsDetail: IntentStatisticsDetail) {
        this.intentStatisticsDetail = intentStatisticsDetail;
    }

    @action
    clear() {
        this.statCounts = undefined;
        this.statCountsDetails = [];
        this.userSentencesMap = {};
        this.state = "none";
    }
}

export default StatisticsStore;
