import axios from "axios";
import { API_HOST } from "../variables";

export const createDomain = (agentId: number, domainName: string) => {
    return axios
        .post(`${API_HOST}/v1/agents/${agentId}/domains`, {
            name: domainName
        })
        .then((response) => Promise.resolve(response))
        .catch((error) => Promise.reject(error));
};

export const updateDomain = (agentId: number, domainId: number, domainName: string) => {
    return axios
        .put(`${API_HOST}/v1/agents/${agentId}/domains/${domainId}`, {
            name: domainName,
            domain_id: domainId
        })
        .then((response) => Promise.resolve(response))
        .catch((error) => Promise.reject(error));
};

export const deleteDomain = (agentId: number, domainIds: number[]) => {
    const params = new URLSearchParams();
    domainIds.forEach((id) => {
        params.append("ids", `${id}`);
    });
    return axios
        .delete(`${API_HOST}/v1/agents/${agentId}/domains`, {
            params: params
        })
        .then((response) => Promise.resolve(response))
        .catch((error) => Promise.reject(error));
};

export const getSkills = (agentId: number, domainId: number) => {
    return axios
        .get(`${API_HOST}/v1/agents/${agentId}/domains/${domainId}/skills`, {
            params: {
                agent_id: agentId
            }
        })
        .then((response) => Promise.resolve(response))
        .catch((error) => Promise.reject(error));
};

export const deleteSkill = (agentId: number, domainId: number, skillId: number, skillName: string) => {
    return axios
        .delete(`${API_HOST}/v1/agents/${agentId}/domains/${domainId}/skills/${skillId}`, {
            data: {
                skill_name: skillName
            }
        })
        .then((response) => Promise.resolve(response))
        .catch((error) => Promise.reject(error));
};

export const createSkill = (agentId: number, domainId: number, skillName: string) => {
    return axios
        .post(`${API_HOST}/v1/agents/${agentId}/domains/${domainId}/skills`, {
            name: skillName
        })
        .then((response) => Promise.resolve(response))
        .catch((error) => Promise.reject(error));
};

export const updateSkillName = (agentId: number, domainId: number, skillId: number, skillName: string) => {
    return axios
        .put(`${API_HOST}/v1/agents/${agentId}/domains/${domainId}/skills/${skillId}`, {
            name: skillName
        })
        .then((response) => Promise.resolve(response))
        .catch((error) => Promise.reject(error));
};

export const findDomains = (agentId: number) => {
    return axios.get(`${API_HOST}/v1/agents/${agentId}/domains`);
};

export const getEntityTags = (agentId: number, domainId: number) => {
    return axios
        .get(`${API_HOST}/v1/agents/${agentId}/domains/${domainId}/tags`)
        .then((response) => Promise.resolve(response))
        .catch((error) => Promise.reject(error));
};
