import { action, observable } from "mobx";
import { emptyPage, Page } from "../model/Page";
import { Entity } from "../model/Entity";
import { StoreState } from "../model/StoreState";
import * as entityApis from "../apis/entityApis";

class EntityListStore {
    @observable
    data: Page<Entity> = emptyPage();

    @observable
    keyword?: string;

    @observable
    state: StoreState = "none";

    @action
    async load(agentId: number, domainId: number, entityNameId: number, page: number = 0, size: number = 15, keyword?: string) {
        this.state = "pending";
        this.keyword = keyword;
        try {
            const { data } = await entityApis.findEntities(agentId, domainId, entityNameId, page, size, keyword);
            data.data.content = data.data.content.map((sym: Entity) => {
                sym.synonyms = this.synonymSort(sym);
                return sym;
            })
            this.data = data.data;
            this.state = "done";
        } catch (e) {
            this.state = "error";
            throw e;
        }
    }

    @action
    clear() {
        this.data = emptyPage();
        this.keyword = undefined;
        this.state = "none";
    }

    @action
    synonymSort(synonym: Entity) {
        return synonym.synonyms?.sort()
    }
}

export default EntityListStore;
