import React from "react";
import {action, observable} from "mobx";

class DeleteDialogStore {

    private defaultText: string = '';
    private onDeleteDefault: VoidFunction = (() => {
    });

    @observable
    isOpen: boolean = false;

    @observable
    text: string = this.defaultText;

    @observable
    onDelete: VoidFunction = this.onDeleteDefault;

    @action
    open(onDelete: VoidFunction, text?: string) {
        this.isOpen = true;
        this.text = text || this.defaultText;
        this.onDelete = onDelete;
    }

    @action
    close() {
        this.isOpen = false;
        this.text = this.defaultText;
        this.onDelete = this.onDeleteDefault;
    }
}

const deleteDialogContext = React.createContext(new DeleteDialogStore());
export const useDeleteDialog = () => React.useContext(deleteDialogContext);