import {action, observable} from "mobx";
import React from "react";


class BlockUiStore {

    @observable
    blocking: boolean = false;

    @action
    on() {
        this.blocking = true;
    }

    @action
    off() {
        this.blocking = false;
    }
}

const blockUiContext = React.createContext(new BlockUiStore());
export const useBlockUi = () => React.useContext(blockUiContext);