import axios from "axios";
import { API_HOST } from "../variables";

export const loadLoginStatus = () => {
    return axios.get(`${API_HOST}/v1/auth/me`);
};

export const checkPermission = (agentId: number) => {
    return axios.get(`${API_HOST}/v1/auth/authorization`, {
        params: { agent_id: agentId }
    });
};

export const logout = () => {
    return axios.post(`${API_HOST}/v1/auth/logout`);
};
