import axios from "axios";
import { API_HOST } from "../variables";

export const getSelectorData = (agentId: number, page: number, size: number = 15, keyword?: string) => {
    return axios
        .get(`${API_HOST}/v1/agents/${agentId}/selectors`, {
            params: {
                page: page,
                size: size,
                agent_id: agentId,
                sentence: keyword
            }
        })
        .then((response) => Promise.resolve(response))
        .catch((error) => Promise.reject(error));
};

export const postRegisterSentence = (agentId: number, intent_id: string, intent_name: string, sentence: string) => {
    return axios
        .post(`${API_HOST}/v1/agents/${agentId}/selectors`, {
            agent_id: agentId,
            intent_id,
            intent_name,
            sentence
        })
        .then((response) => Promise.resolve(response))
        .catch((error) => Promise.reject(error));
};

export const putSentence = (agentId: number, selector_id: string, intent_id: string, intent_name: string, sentence: string) => {
    return axios
        .put(`${API_HOST}/v1/agents/${agentId}/selectors/${selector_id}`, {
            agent_id: agentId,
            intent_id,
            intent_name,
            sentence
        })
        .then((response) => Promise.resolve(response))
        .catch((error) => Promise.reject(error));
};

export const deleteSentence = (agentId: number, ids: string[]) => {
    const params = new URLSearchParams();
    ids.forEach((id) => {
        params.append("ids", `${id}`);
    });
    return axios
        .delete(`${API_HOST}/v1/agents/${agentId}/selectors`, {
            params: params
        })
        .then((response) => Promise.resolve(response))
        .catch((error) => Promise.reject(error));
};

export const postBulkSelector = (agentId: number, selector_file: any, isCleanImport?: boolean) => {
    let form = new FormData();
    const is_clean_import = isCleanImport ? '1' : '0'
    form.append("is_clean_import", is_clean_import)
    form.append("file", selector_file);

    return axios
        .post(`${API_HOST}/v1/agents/${agentId}/selectors/import`, form)
        .then((response) => Promise.resolve(response))
        .catch((error) => Promise.reject(error));
};

export const downloadSelector = (agentId: number) => {
    let url = `${API_HOST}/v1/agents/${agentId}/selectors/export`;
    window.open(url);
};

export const findSelectorSentences = (agentId: number, page: number = 0, size: number = 15, keyword?: string) => {
    return axios.get(`${API_HOST}/v1/agents/${agentId}/selectors`, {
        params: {
            page: page,
            size: size,
            sentence: keyword
        }
    });
};

export const deleteSelectorSentences = (agentId: number, ids: string[]) => {
    const params = new URLSearchParams();
    ids.forEach((id) => {
        params.append("ids", `${id}`);
    });
    return axios.delete(`${API_HOST}/v1/agents/${agentId}/selectors`, {
        params: params
    });
};
