import React from 'react';
import {Button, Modal} from 'react-bootstrap';
import {observer} from 'mobx-react-lite';
import {useDeleteDialog} from "../../hooks/useDeleteDialog";
import {useIntl} from "react-intl";


export const DeleteDialog: React.FC = observer(() => {

    const deleteDialog = useDeleteDialog();
    const intl = useIntl();

    const handleClose = () => {
        deleteDialog.close();
    };

    const onClickDeleteBtn = () => {
        deleteDialog.onDelete();
        deleteDialog.close();
    };

    return (
        <Modal show={deleteDialog.isOpen} size="sm" onHide={handleClose}>
            <Modal.Body className="pt-4">
                <div className="text-center">
                    <i className="mdi mdi-trash-can-outline h1"/>
                    <h4 className="mt-2">DELETE</h4>
                    <p className="mt-3">{deleteDialog.text || intl.formatMessage({id: 'i000252'})}</p>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant={'danger'} onClick={onClickDeleteBtn}>{intl.formatMessage({id: 'i000161'})}</Button>
                <Button variant={'secondary'} onClick={handleClose}>{intl.formatMessage({id: 'i000025'})}</Button>
            </Modal.Footer>
        </Modal>
    );
});

