import loadable from '@loadable/component';

export const agentListView = loadable(() => import("./agent/AgentListView"));
export const agentRegistrationView = loadable(() => import("./agent/AgentRegistrationView"));
export const agentSettingView = loadable(() => import("./agent/AgentSettingView"));

export const dashboardView = loadable(() => import("./dashboard/DashboardRouteView"));
export const conversationView = loadable(() => import("./conversation/ConversationRouteView"));

export const domainRouteView = loadable(() => import('./domain/DomainRouteView'));
export const skillIntentRouteView = loadable(() => import("./domain/SkillIntentRouteView"));
export const clusteringView = loadable(() => import("./domain/ClusteringView"));

export const correctionKeywordView = loadable(() => import("./correction/CorrectionKeywordView"));

export const entityNameView = loadable(() => import("./entity/EntityNameView"));
export const entityView = loadable(() => import("./entity/EntityView"));

export const synonymView = loadable(() => import("./synonym/SynonymRouteView"));

export const selectorView = loadable(() => import("./selector/SelectorRouteView"));

export const autocompleteView = loadable(() => import("./autocomplete/AutocompleteRouteView"));
export const autocompleteDetailView = loadable(() => import("./autocomplete/AutocompleteDetailRouteView"));

export const bulkTestView = loadable(() => import("./simulator/BulkTestRouteView"));
export const bulkTestReportView = loadable(() => import("./simulator/BulkTestReportRouteView"));

export const statisticsView = loadable(() => import("./statistics/StatisticsView"));

export const notFoundRedirectView = loadable(() => import("./error/NotFoundRedirectView"));
export const notFoundView = loadable(() => import("./error/NotFoundView"));
export const forbiddenView = loadable(() => import("./error/ForbiddenView"));