import { action, computed, observable } from "mobx";
import { Agent } from "../model/Agent";
import { StoreState } from "../model/StoreState";
import * as agentApis from "../apis/agentApis";
import { emptyPage, Page } from "../model/Page";

class AgentListStore {
    @observable
    agentPage: Page<Agent> = emptyPage();

    @observable
    state: StoreState = "none";

    @action
    async load(active: boolean, page: number, size: number, keyword?: string) {
        this.state = "pending";
        try {
            const { data } = await agentApis.findAgentPage(active, page, size, keyword);
            this.agentPage = data.data;
            this.state = "done";
        } catch (e) {
            this.state = "error";
            throw e;
        }
    }

    @action
    clear() {
        this.agentPage = emptyPage();
        this.state = "none";
    }

    @computed
    get agents() {
        return this.agentPage.content;
    }

    @computed
    get page() {
        return this.agentPage.page;
    }

    @computed
    get totalPage() {
        return this.agentPage.total_pages;
    }
}

export default AgentListStore;
