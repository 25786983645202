import { action, observable } from "mobx";
import { emptyPage, Page } from "../model/Page";
import { Synonym } from "../model/Synonym";
import { StoreState } from "../model/StoreState";
import * as synonymApis from "../apis/synonymApis";

class SynonymListStore {
    @observable
    data: Page<Synonym> = emptyPage();

    @observable
    keyword?: string;

    @observable
    state: StoreState = "none";

    @action
    async load(agentId: number, page: number, size: number, keyword?: string) {
        this.state = "pending";
        this.keyword = keyword;
        try {
            const { data } = await synonymApis.findSynonyms(agentId, page, size, keyword);
            data.data.content = data.data.content.map((sym: Synonym) => {
                sym.synonyms = this.synonymSort(sym);
                return sym;
            })
            this.data = data.data;
            this.state = "done";
        } catch (e) {
            this.state = "error";
            throw e;
        }
    }

    @action
    clear() {
        this.data = emptyPage();
        this.keyword = undefined;
        this.state = "none";
    }

    @action
    synonymSort(synonym: Synonym) {
        const result: string[] = [synonym.keyword]

        return result.concat(synonym.synonyms?.filter(value => value !== synonym.keyword).sort())
    }
}

export default SynonymListStore;
