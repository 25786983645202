import axios from 'axios'
import { API_HOST } from "../variables";


export const getIntentPredict = (query: string, agent_token: string, debug:boolean, category:string) => {
    return axios
        .get(`${API_HOST}/intent`, {
            params: {
                query:query,
                agent_token: agent_token,
                debug:debug,
                category:category
            }
        })
        .then(response => Promise.resolve(response))
        .catch(error => Promise.reject(error));
};

export const getAgentInfo = (agentId: number) => {
    return axios
        .get(`${API_HOST}/v1/agents`, {
            params: {
                agent_id: agentId
            }
        })
        .then(response => Promise.resolve(response))
        .catch(error => Promise.reject(error));
};

export const getTestSuggestions = (autocomplete_token: string, query: string) => {
    return axios.get(`${API_HOST}/v1/service/autocompletes/${autocomplete_token}`, {
        params: {
            q: query,
        }
    })
        .then((response) => Promise.resolve(response))
        .catch((error) => Promise.reject(error));
};
