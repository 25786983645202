import { Moment } from "moment"

export const zeroTime = (datetime: Moment): Moment => {
    datetime.set({hour: 0, minute: 0, second: 0, millisecond: 0});
    return datetime
};

export const lastTime = (datetime: Moment): Moment => {
    datetime.set({hour: 23, minute: 59, second: 59, millisecond: 999});
    return datetime
};

export const getUtcZeroTime = (moment: Moment, format: string): string  => {
    return moment.clone().utc().format(format)
};
