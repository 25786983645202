import React from "react";
import {action, observable} from "mobx";
import {Button, Modal} from "react-bootstrap";
import ReactJson from "react-json-view-ts";
import {observer} from "mobx-react-lite";
import {useIntl} from "react-intl";

class JSONViewerStore {
    @observable
    json: object = {};

    @observable
    title?: string;

    @observable
    isOpen: boolean = false;

    @action
    open(title: string, json: object) {
        this.isOpen = true;
        this.title = title;
        this.json = json;
    }

    @action
    close() {
        this.isOpen = false;
        this.json = {};
        this.title = undefined;
    }

}

const context = React.createContext(new JSONViewerStore());
const useJSONViewer = () => React.useContext(context);


export const JSONViewer: React.FC = observer(() => {
    const jsonViewer = useJSONViewer();
    const intl = useIntl();

    const onClickCloseBtn = (e: React.MouseEvent) => {
        e.preventDefault();
        jsonViewer.close();
    };

    const onHide = () => {
        jsonViewer.close();
    };

    return (
        <Modal show={jsonViewer.isOpen} onHide={onHide} size={'lg'} centered scrollable>
            <Modal.Header closeButton>
                <Modal.Title as={'h4'}>{jsonViewer.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ReactJson src={jsonViewer.json} name={false} displayObjectSize={false} displayDataTypes={false} />
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onClickCloseBtn}>{intl.formatMessage({id: 'i000042'})}</Button>
            </Modal.Footer>
        </Modal>
    );
});

export default useJSONViewer;

