import { action, observable } from "mobx";


class AutocompleteTestStore {
    @observable
    isOpen: boolean = false;

    @observable
    suggestions: string[] = [];

    @action
    open() {
        this.isOpen = true;
    }

    @action
    close() {
        this.suggestions = [];
        this.isOpen = false;
    }

    @action
    clearSuggestions() {
        this.suggestions = [];
    }
}

export default AutocompleteTestStore;