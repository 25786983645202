import { action, observable } from "mobx";
import IntentPredictResult from "../model/IntentPredictResult";
import * as intentApis from "../apis/intentApis";
import { StoreState } from "../model/StoreState";

class IntentPredictStore {
    @observable
    result?: IntentPredictResult;

    @observable
    state: StoreState = "none";

    @action
    async predict(query: string, agentToken: string, debug: boolean, category: string) {
        this.state = "pending";
        try {
            const { data } = await intentApis.predict(query, agentToken, debug, category);
            this.result = data.data;
            this.state = "done";
        } catch (e) {
            this.result = undefined;
            this.state = "error";
            throw e;
        }
    }

    @action
    clear() {
        this.result = undefined;
        this.state = "none";
    }
}

export default IntentPredictStore;
