import * as io from "socket.io-client";
import { ManagerOptions, Socket, SocketOptions } from "socket.io-client";

export interface NLUSocketOptions {
    client_id: string;
    agent_id: number;
    client_type?: string;
    name?: string;
    email?: string;
    uid?: string;
}

export class NLUSocket {
    host: string;
    path: string;
    socket?: Socket;
    opts?: Partial<ManagerOptions & SocketOptions>;

    constructor(host: string, path?: string, opts?: Partial<ManagerOptions & SocketOptions>) {
        this.host = host;
        this.opts = opts;
        if (path) {
            this.path = path;
        } else {
            this.path = "/socket.io";
        }
    }

    connect = (options?: NLUSocketOptions, callback?: Function) => {
        this.socket = io.connect(this.host, {
            path: this.path,
            query: { ...options, client_type: "nlu" },
            reconnection: true,
            reconnectionDelay: 500,
            reconnectionAttempts: Infinity,
            ...this.opts
        });

        if (callback) {
            this.on("connected", (response: object) => {
                callback(response);
            });
        }
    };

    disconnect = () => {
        if (this.socket && this.socket.connected) {
            this.socket.disconnect();
        } else {
            throw new Error("There is no connection");
        }
    };

    on = (event: string, callback: Function) => {
        if (this.socket) {
            this.socket.on(event, (response: object) => {
                callback(response);
            });
        } else {
            throw new Error("Socket connect function should be preceded");
        }
    };

    emit = (event: string, data: object) => {
        if (this.socket) {
            this.socket.emit(event, data);
        } else {
            throw new Error("There is no connection");
        }
    };
}
