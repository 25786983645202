import {action, observable} from "mobx";

class LanguageSettingStore {
    @observable
    language: string = localStorage.getItem('language') || 'ko';

    @action
    setLanguage(language: string) {
        localStorage.setItem('language', language);
        this.language = language;
    }
}

export const languages: {[key: string]: string} = {
    'ko': '한국어',
    'en': 'English'
}

export default LanguageSettingStore;