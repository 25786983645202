import axios from "axios";
import { ActiveForm, AgentForm } from "../model/Agent";
import { API_HOST } from "../variables";

export const findAgentPage = (active: boolean, page: number, size: number, keyword?: string) => {
    return axios.get(`${API_HOST}/v1/agents/paginate`, {
        params: {active, page, size, keyword}
    });
};

export const readAgent = (agentId: number) => {
    return axios.get(`${API_HOST}/v1/agents/${agentId}`);
};

export const createAgent = (form: AgentForm) => {
    return axios.post(`${API_HOST}/v1/agents`, {
        ...form
    });
};

export const updateAgent = (agentId: number, form: AgentForm | ActiveForm) => {
    return axios.put(`${API_HOST}/v1/agents/${agentId}`, {
        ...form
    });
};


export const getPermission = (agentId: number) => {
    return axios.get(`${API_HOST}/v1/agents/${agentId}/permission`);
};

export const getAgentSummary = (agentId: number) => {
    return axios
        .get(`${API_HOST}/v1/agents/${agentId}/summary`)
        .then((response) => Promise.resolve(response))
        .catch((error) => Promise.reject(error));
};

export const postCheckout = (agentId: number, keepData: boolean) => {
    return axios
        .post(`${API_HOST}/v1/agents/${agentId}/versions/checkout`, {
            agent_id: agentId,
            keep_data: `${keepData}`
        })
        .then((response) => Promise.resolve(response))
        .catch((error) => Promise.reject(error));
};

export const trainingSandbox = (agentId: number) => {
    return axios
        .get(`${API_HOST}/v1/agents/${agentId}/training`)
        .then((response) => Promise.resolve(response))
        .catch((error) => Promise.reject(error));
};

export const applyService = (agentId: number) => {
    return axios
        .post(`${API_HOST}/v1/agents/${agentId}/versions/deploy`)
        .then((response) => Promise.resolve(response))
        .catch((error) => Promise.reject(error));
};

export const findIntentIds = (agentId: number) => {
    return axios.get(`${API_HOST}/v1/agents/${agentId}/map`);
};

export const findAgentDeployHistory = (agentId: number, page: number, size: number) => {
    return axios.get(`${API_HOST}/v1/agents/${agentId}/history`, {
        params: {
            page: page,
            size: size
        }
    });
};

export const importAgent = (agent_id: number, file: any, isCleanImport?: boolean) => {
    let form = new FormData();
    const is_clean_import = isCleanImport ? '1' : '0'
    form.append("is_clean_import", is_clean_import)
    form.append("agent_id", String(agent_id));
    form.append("file", file);

    return axios
        .post(`${API_HOST}/v1/agents/${agent_id}/import`, form)
        .then((response) => Promise.resolve(response))
        .catch((error) => Promise.reject(error));
};

export const changeServiceVersion = (agentId: number, version: number) => {
    return axios
        .put(`${API_HOST}/v1/agents/${agentId}/versions`, {
            version: version
        })
        .then((response) => Promise.resolve(response))
        .catch((error) => Promise.reject(error));
};
