import { action, observable } from "mobx";
import { Autocomplete, AutocompleteHolder } from "../model/Autocomplete";
import { emptyPage, Page } from "../model/Page";

class AutocompleteStore {
    @observable
    holderPage: Page<AutocompleteHolder> = emptyPage();

    @observable
    holder?: AutocompleteHolder;

    @observable
    autocompletePage: Page<Autocomplete> = emptyPage();

    @observable
    searchKeyword: string = "";

    @observable
    autoCompleteHoldersAll: AutocompleteHolder[] = [];

    @action
    setHolders(holderPage: Page<AutocompleteHolder>) {
        this.holderPage = holderPage;
    }

    @action
    setHolder(holder: AutocompleteHolder) {
        this.holder = holder;
    }

    @action
    setAutocompletePage(autocompletePage: Page<Autocomplete>) {
        this.autocompletePage = autocompletePage;
    }

    @action
    setSearchKeyword(keyword: string) {
        this.searchKeyword = keyword;
    }

    @action
    setAutoCompleteHoldersAll(autocompletes: AutocompleteHolder[]) {
        this.autoCompleteHoldersAll = autocompletes;
    }

    @action
    clear() {
        this.holderPage = emptyPage();
        this.holder = undefined;
        this.autocompletePage = emptyPage();
        this.searchKeyword = "";
    }

    @action
    clearAutoCompleteHoldersAll() {
        this.autoCompleteHoldersAll = []
    }
}

export default AutocompleteStore;