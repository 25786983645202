import { action, observable } from "mobx";
import { Agent } from "../model/Agent";
import { StoreState } from "../model/StoreState";
import * as agentApis from "../apis/agentApis";

class AgentStore {
    @observable
    agent?: Agent;

    @observable
    state: StoreState = "none";

    @observable
    isInactiveAgent: boolean = false;

    @action
    async readAgent(agentId: number) {
        this.state = "pending";
        try {
            const { data } = await agentApis.readAgent(agentId);
            this.agent = data.data;
            this.state = "done";
        } catch (e) {
            this.state = "error";
            this.isInactiveAgent = true;
            throw e;
        }
    }

    @action
    setIsInactiveAgent() {
        this.isInactiveAgent = !this.isInactiveAgent;
    }

    @action
    clear() {
        this.state = "none";
        this.agent = undefined;
    }
}

export default AgentStore;
