import { action, observable } from "mobx";
import { SentenceCluster, SimpleIntent, ClusteringResult, ClusteringTableResult, ClusterSelected } from "../model/Domain";
import * as intentApis from "../apis/intentApis";
import * as clusterApis from "../apis/clusterApis";
import { StoreState } from "../model/StoreState";

class ClusteringStore {
    @observable
    intents: SimpleIntent[] = [];

    @observable
    selectedIntent?: SimpleIntent;

    @observable
    sentenceClusters: SentenceCluster[] = [];

    @observable
    selectedCluster: ClusterSelected = { index: 0, label: 0, sentence: "" };

    @observable
    graphClustering: ClusteringResult = { nodes: [], links: [] };

    @observable
    tableClustering: ClusteringTableResult = { labels: [], sentences: [] };

    @observable
    state: StoreState = "none";

    @action
    async findIntents(agentId: number, domainId: number) {
        this.state = "pending";
        try {
            const { data } = await intentApis.findIntents(agentId, domainId);
            this.intents = [...data].map((intent) => ({ id: intent.intent_id, name: intent.intent_name }));
            this.selectedIntent = this.intents.length > 0 ? this.intents[0] : undefined;
            this.state = "done";
        } catch (e) {
            this.state = "error";
            throw e;
        }
    }

    @action
    async findSentenceClustering(agentId: number, intentId: number) {
        this.state = "pending";
        try {
            const { data } = await clusterApis.findSentences(agentId, intentId);
            this.sentenceClusters = data;
            this.state = "done";
        } catch (e) {
            this.state = "error";
            throw e;
        }
    }

    @action
    async findGraphClustering(agentId: number) {
        this.state = "pending";
        try {
            const { data } = await clusterApis.findClustering(agentId);
            this.graphClustering = data;
            this.state = "done";
        } catch (e) {
            this.state = "error";
            throw e;
        }
    }

    @action
    async findTableClustering(agentId: number) {
        this.state = "pending";
        try {
            const { data } = await clusterApis.findTableClustering(agentId);
            this.tableClustering = data;
            this.state = "done";
        } catch (e) {
            this.state = "error";
            throw e;
        }
    }

    @action
    selectCluster(cluster: ClusterSelected) {
        this.selectedCluster = cluster;
    }

    @action
    selectIntent(intent: SimpleIntent) {
        this.selectedIntent = intent;
    }

    @action
    clear() {
        this.intents = [];
        this.selectedIntent = undefined;
        this.selectedCluster = { index: 0, label: 0, sentence: "" };
        this.graphClustering = { nodes: [], links: [] };
        this.tableClustering = { labels: [], sentences: [] };
        this.state = "none";
    }
}

export default ClusteringStore;
