import axios from "axios";
import { API_HOST } from "../variables";
import { SlotForm } from "../model/Domain";

export const findIntents = (agentId: number, domainId: number) => {
    return axios.get(`${API_HOST}/v1/agents/${agentId}/domains/${domainId}/intents`);
};

export const findIntentNames = (agentId: number) => {
    return axios.get(`${API_HOST}/v1/agents/${agentId}/intents`);
};

export const predict = (query: string, agentToken: string, debug: boolean, category: string) => {
    return axios.get(`${API_HOST}/v1/service/intent`, {
        params: {
            query: query,
            agent_token: agentToken,
            debug: debug,
            category: category,
            source: "nlu-web",
            channel: "try_it_out"
        }
    });
};

export const createIntent = (agentId: number, domainId: number, skillId: number, intentName: string) => {
    return axios
        .post(`${API_HOST}/v1/agents/${agentId}/domains/${domainId}/skills/${skillId}/intents`, {
            name: intentName
        })
        .then((response) => Promise.resolve(response))
        .catch((error) => Promise.reject(error));
};

export const getIntents = (agentId: number, domainId: number, skillId: number) => {
    return axios
        .get(`${API_HOST}/v1/agents/${agentId}/domains/${domainId}/skills/${skillId}/intents`)
        .then((response) => Promise.resolve(response))
        .catch((error) => Promise.reject(error));
};

export const getIntentSentences = (agentId: number, domainId: number, skillId: number, intentId: number) => {
    return axios
        .get(`${API_HOST}/v1/agents/${agentId}/domains/${domainId}/skills/${skillId}/intents/${intentId}/sentences`)
        .then((response) => Promise.resolve(response))
        .catch((error) => Promise.reject(error));
};

export const uploadSkillIntents = (agentId: number, file: File, isCleanImport?: boolean) => {
    const form = new FormData();
    const is_clean_import = isCleanImport ? '1' : '0'
    form.append("is_clean_import", is_clean_import)
    form.append("file", file);

    return axios
        .post(`${API_HOST}/v1/agents/${agentId}/intents/import`, form)
        .then((response) => Promise.resolve(response))
        .catch((error) => Promise.reject(error));
};

export const updateIntent = (
    agentId: number,
    domainId: number,
    skillId: number,
    intentId: number,
    data?: {
        name?: string;
        slot_info?: SlotForm[];
        answers?: string[];
        active?: boolean;
        config?: { use_intent_threshold?: boolean; intent_threshold?: number };
    }
) => {
    return axios
        .put(`${API_HOST}/v1/agents/${agentId}/domains/${domainId}/skills/${skillId}/intents/${intentId}`, data)
        .then((response) => Promise.resolve(response))
        .catch((error) => Promise.reject(error));
};

export const deleteIntent = (agentId: number, domainId: number, skillId: number, intentId: number, intentName: string) => {
    return axios
        .delete(`${API_HOST}/v1/agents/${agentId}/domains/${domainId}/skills/${skillId}/intents/${intentId}`, {
            data: {
                name: intentName
            }
        })
        .then((response) => Promise.resolve(response))
        .catch((error) => Promise.reject(error));
};

export const createSentence = (agentId: number, domainId: number, skillId: number, intentId: number, sentence: string) => {
    return axios
        .post(`${API_HOST}/v1/agents/${agentId}/domains/${domainId}/skills/${skillId}/intents/${intentId}/sentences`, {
            sentence: sentence
        })
        .then((response) => Promise.resolve(response))
        .catch((error) => Promise.reject(error));
};

export const updateSentenceAnswers = (
    agentId: number,
    domainId: number,
    skillId: number,
    intentId: number,
    answerMap?: { [id: string]: string[] }
) => {
    return axios
        .put(`${API_HOST}/v1/agents/${agentId}/domains/${domainId}/skills/${skillId}/intents/${intentId}/sentences/answers`, answerMap)
        .then((response) => Promise.resolve(response))
        .catch((error) => Promise.reject(error));
};

export const updateSentence = (
    agentId: number,
    domainId: number,
    skillId: number,
    intentId: number,
    sentenceKey: string,
    sentence: string,
    userKey?: number
) => {
    return axios
        .put(`${API_HOST}/v1/agents/${agentId}/domains/${domainId}/skills/${skillId}/intents/${intentId}/sentences/${sentenceKey}`, {
            sentence: sentence,
            user_key: userKey
        })
        .then((response) => Promise.resolve(response))
        .catch((error) => Promise.reject(error));
};

export const deleteSentence = (
    agentId: number,
    domainId: number,
    skillId: number,
    intentId: number,
    sentenceKey: string,
    sentence: string
) => {
    return axios
        .delete(`${API_HOST}/v1/agents/${agentId}/domains/${domainId}/skills/${skillId}/intents/${intentId}/sentences/${sentenceKey}`)
        .then((response) => Promise.resolve(response))
        .catch((error) => Promise.reject(error));
};

export const getSentences = (agentId: number, domainId: number, skillId: number, intentId: number) => {
    return axios
        .get(`${API_HOST}/v1/agents/${agentId}/domains/${domainId}/skills/${skillId}/intents/${intentId}/sentences`)
        .then((response) => Promise.resolve(response))
        .catch((error) => Promise.reject(error));
};
