import {action, observable} from "mobx";
import {Account} from "../model/account";
import * as accountApis from "../apis/accountApis";

class AccountStore {

    @observable
    account?: Account;

    @action
    async getProfile() {
        try {
            const { data } = await accountApis.getProfile();
            this.account = data.data;
        } catch (e) {
            throw e;
        }
    }

    @action
    clear() {
        this.account = undefined;
    }
}

export default AccountStore;