import {action, observable} from "mobx";
import {Synonym} from "../model/Synonym";

export class SynonymEditingStore {
    @observable
    synonym?: Synonym;

    @observable
    editingKeyword?: string;

    @observable
    editingSynonyms: string[] =[];

    @observable
    unadaptedSynonyms: string | undefined = undefined;

    @action
    openEditForm(synonym: Synonym) {
        this.synonym = synonym;
        this.editingKeyword = synonym.keyword;
        this.editingSynonyms = synonym.synonyms;
        this.unadaptedSynonyms = undefined;
    }

    @action
    closeEditForm() {
        this.synonym = undefined;
        this.editingSynonyms = [];
        this.editingKeyword = undefined;
    }

    @action
    setEditingSynonyms(synonyms: string[]) {
        this.editingSynonyms = synonyms;
    }

    @action
    setEditingKeyword(keyword?: string) {
        this.editingKeyword = keyword;
    }

    @action
    setUnadaptedSynonyms(query: string | undefined) {
        this.unadaptedSynonyms = query;
    }

    @action
    dirty() {
        if (!this.synonym) {
            return false;
        }
        const changedSynonyms = JSON.stringify(this.synonym.synonyms) !== JSON.stringify(this.editingSynonyms);
        return this.synonym.keyword !== this.editingKeyword || changedSynonyms
    }

    @action
    clear() {
        this.synonym = undefined;
        this.editingSynonyms = [];
        this.editingKeyword = undefined;
        this.unadaptedSynonyms = "";
    }

}