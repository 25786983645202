import React, {useEffect, useMemo} from "react";
import {Dropdown} from "react-bootstrap";
import { Link, useHistory, useParams } from "react-router-dom";
import {observer} from "mobx-react-lite";
import {useStores} from "../hooks/useStores";
import {useIntl} from "react-intl";


const AgentSelector: React.FC = observer(() => {
    const {recentAgentsStore, agentStore, agentListStore} = useStores();
    const { agentId } = useParams();

    const intl = useIntl();
    const history = useHistory();

    if (!agentId) {
        throw new Error('No agentId found.');
    }

    useEffect(() => {
        agentListStore.load(true, agentListStore.page, 10).then(() => {

        }).catch(error => {
            console.error(error);
        });

        agentStore.readAgent(+agentId).then(() => {

        }).catch(error => {
            console.error(error);
            history.push('/');
        });

        return () => {
            agentListStore.clear();
            agentStore.clear();
        };
    }, [history, agentListStore, agentId, agentStore]);

    const unselectAgents = useMemo(() => {
        return agentListStore.agents.filter(agent =>
            agent.id !== +agentId && !recentAgentsStore.data.some(recentAgentId => recentAgentId === agent.id));
    }, [agentListStore.agents, agentId, recentAgentsStore.data]);


    return (
        <Dropdown>
            <Dropdown.Toggle as={'button'} className="btn-application-selector" id={'agent-selector'}>
                <span>{agentStore.agent?.name}</span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
                <RecentAgentsFragment />
                {unselectAgents.slice(0, 10).map(agent =>
                    <Dropdown.Item key={agent.id} as={Link} to={`/${agent.id}/dashboard`}>{agent.name}</Dropdown.Item>
                )}
                <Dropdown.Divider />
                <Dropdown.Item as={Link} to={'/agents'}>{intl.formatMessage({id: 'i000251'})}</Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );
});

const RecentAgentsFragment: React.FC = observer(() => {
    const {recentAgentsStore, agentListStore} = useStores();
    const intl = useIntl();
    const {agentId} = useParams();

    const recentAgents = useMemo(() => {
        if (agentListStore.agents.length > 0) {
            return recentAgentsStore.data.filter(recentAgentId => {
                return agentListStore.agents.some(agent => agent.id === recentAgentId) && +agentId !== recentAgentId;
            }).map(recentAgentId => {
                const filtered = agentListStore.agents.filter(agent => agent.id === recentAgentId)
                return filtered[0];
            });
        }

        return [];
    }, [agentListStore.agents, recentAgentsStore.data, agentId]);

    if (recentAgents.length > 0) {
        return (
            <>
                <Dropdown.Header><strong className="text-muted">{intl.formatMessage({id: 'i000260'})}</strong></Dropdown.Header>
                {recentAgents.map(agent =>
                    <Dropdown.Item key={agent.id} as={Link} to={`/${agent.id}/dashboard`}>{agent.name}</Dropdown.Item>
                )}
                <Dropdown.Divider />
            </>
        );
    }
    else {
        return null;
    }
});

export default AgentSelector;