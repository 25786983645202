import React from "react";
import * as Layout from "@42maru/web-starter";
import {useStores} from "../../hooks/useStores";
import {observer} from "mobx-react-lite";


const Footer: React.FC = observer(() => {

    const {singleTestPanelStore} = useStores();

    return (
        <Layout.Footer
            copyright={'Copyright © 2019 42Maru Inc, All rights reserved.'}
            style={{right: singleTestPanelStore.isOpen ? '450px' : '0'}}
        />
    );
});

export default Footer;