import axios from "axios";
import { API_HOST } from "../variables";

export const postSynonymKeyword = (agentId: number, keyword: string, synonyms: string[]) => {
    return axios
        .post(`${API_HOST}/v1/agents/${agentId}/synonyms`, {
            keyword,
            synonyms
        })
        .then((response) => Promise.resolve(response))
        .catch((error) => Promise.reject(error));
};

export const postBulkSynonymKeyword = (agentId: number, file: any, isCleanImport?: boolean) => {
    let form = new FormData();
    const is_clean_import = isCleanImport ? '1' : '0'
    form.append("is_clean_import", is_clean_import)
    form.append("file", file);

    return axios
        .post(`${API_HOST}/v1/agents/${agentId}/synonyms/import`, form)
        .then((response) => Promise.resolve(response))
        .catch((error) => Promise.reject(error));
};

export const findSynonyms = (agentId: number, page: number, size: number, keyword?: string) => {
    return axios.get(`${API_HOST}/v1/agents/${agentId}/synonyms`, {
        params: {
            page: page,
            size: size,
            keyword: keyword
        }
    });
};

export const updateSynonym = (agentId: number, synonymKeywordId: number, keyword: string, synonyms: string[]) => {
    return axios.put(`${API_HOST}/v1/agents/${agentId}/synonyms/${synonymKeywordId}`, {
        keyword: keyword,
        synonyms: synonyms
    });
};

export const deleteSynonyms = (agentId: number, ids: number[]) => {
    const params = new URLSearchParams();
    ids.forEach((id) => {
        params.append("ids", `${id}`);
    });
    return axios.delete(`${API_HOST}/v1/agents/${agentId}/synonyms`, {
        params: params
    });
};
