import React from 'react';
import {useBlockUi} from "../../hooks/useBlockUi";
import {observer} from "mobx-react-lite";
import styled from "styled-components";
import {Spinner} from "react-bootstrap";

const Loading: React.FC = observer(() => {
    const {blocking} = useBlockUi();

    return (
        <LoadingStyle loading={blocking ? 1 : 0}>
            <Spinner animation={'grow'} variant={'primary'} />
        </LoadingStyle>
    );
});

type LoadingStyleProps = {
    loading: number;
};



const LoadingStyle = styled.div<LoadingStyleProps>`
    display: ${props => props.loading ? 'flex' : 'none'};
    justify-content: center !important;
    align-items: center !important;
    position: fixed !important;
    top: 0;
    left: 260px;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 9999;
`;

export default Loading;