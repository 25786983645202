import axios from "axios";
import { API_HOST } from "../variables";

export const deleteEntityNameData = (agentId: number, domainId: number, ids: number[]) => {
    const params = new URLSearchParams();
    ids.forEach((id) => {
        params.append("ids", `${id}`);
    });

    return axios
        .delete(`${API_HOST}/v1/agents/${agentId}/domains/${domainId}/entities`, {
            params: params
        })
        .then((response) => Promise.resolve(response))
        .catch((error) => Promise.reject(error));
};

export const deleteEntitiesData = (agentId: number, domainId: number, entityNameId: number, ids: number[]) => {
    const params = new URLSearchParams();
    ids.forEach((id) => {
        params.append("ids", `${id}`);
    });
    return axios
        .delete(`${API_HOST}/v1/agents/${agentId}/domains/${domainId}/entities/${entityNameId}/values`, {
            params: params
        })
        .then((response) => Promise.resolve(response))
        .catch((error) => Promise.reject(error));
};


export const findEntityNames = (agentId: number, domainId: number, page?: number, size?: number,
                                keyword?: string, isOptionSort?: boolean, optionList?: string) => {
    return axios.get(`${API_HOST}/v1/agents/${agentId}/domains/${domainId}/entities`, {
        params: {
            keyword: keyword,
            is_option_sort: isOptionSort,
            option_list: optionList,
            page: page,
            size: size
        }
    });
};

export const readEntityName = (agentId: number, domainId: number, entityNameId: string) => {
    return axios.get(`${API_HOST}/v1/agents/${agentId}/domains/${domainId}/entities/${entityNameId}`);
};

export const createEntityName = (
    agentId: number,
    domainId: number,
    entityName: string,
    postProcessing: boolean,
    ignored: boolean
) => {
    return axios.post(`${API_HOST}/v1/agents/${agentId}/domains/${domainId}/entities`, {
        agent_id: agentId,
        domain_id: domainId,
        name: entityName,
        post_processing: postProcessing,
        ignored: ignored
    });
};

export const updateEntityName = (
    agentId: number,
    domainId: number,
    entityNameId: number,
    entityName: string,
    post: boolean,
    ignored: boolean
) => {
    return axios.put(`${API_HOST}/v1/agents/${agentId}/domains/${domainId}/entities/${entityNameId}`, {
        name: entityName,
        post_processing: post,
        ignored: ignored
    });
};

export const bulkUpload = (agentId: number, domainId: number, file: any, isCleanImport?: boolean) => {
    const form = new FormData();
    const is_clean_import = isCleanImport ? '1' : '0'
    form.append("is_clean_import", is_clean_import)
    form.append("file", file);

    return axios.post(`${API_HOST}/v1/agents/${agentId}/domains/${domainId}/entities/import`, form);
};

export const findEntities = (agentId: number, domainId: number, entityNameId: number, page: number, size: number, keyword?: string) => {
    return axios.get(`${API_HOST}/v1/agents/${agentId}/domains/${domainId}/entities/${entityNameId}/values`, {
        params: {
            keyword: keyword,
            page: page,
            size: size
        }
    });
};

export const createEntity = (
    agentId: number,
    domainId: number,
    entityNameId: number,
    value: string,
    mappingValue?: string,
    synonyms?: string[]
) => {
    return axios.post(`${API_HOST}/v1/agents/${agentId}/domains/${domainId}/entities/${entityNameId}/values`, {
        agent_id: agentId,
        entity_id: entityNameId,
        name: value,
        mapping_value: mappingValue,
        synonyms
    });
};

export const updateEntity = (
    agentId: number,
    domainId: number,
    entityNameId: number,
    entityId: number,
    value: string,
    mappingValue?: string,
    synonyms?: string[]
) => {
    return axios.put(`${API_HOST}/v1/agents/${agentId}/domains/${domainId}/entities/${entityNameId}/values/${entityId}`, {
        name: value,
        mapping_value: mappingValue,
        synonyms: synonyms
    });
};
