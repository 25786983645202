import { action, observable } from "mobx";
import { SimpleIntent } from "../model/Domain";
import { StoreState } from "../model/StoreState";
import * as intentApis from "../apis/intentApis";

class IntentNameListStore {
    @observable
    data: SimpleIntent[] = [];

    @observable
    state: StoreState = "none";

    @action
    async load(agentId: number) {
        this.state = "pending";
        try {
            const { data } = await intentApis.findIntentNames(agentId);
            this.data = data.data.map((intent: any) => ({ id: intent.id, name: intent.name }));
            this.state = "done";
        } catch (e) {
            this.state = "error";
            throw e;
        }
    }

    @action
    clear() {
        this.data = [];
        this.state = "none";
    }
}

export default IntentNameListStore;
