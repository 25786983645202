import { action, observable } from "mobx";
import { Domain } from "../model/Domain";
import { StoreState } from "../model/StoreState";
import * as domainApis from "../apis/domainApis";

class DomainListStore {
    @observable
    domains: Domain[] = [];

    @observable
    state: StoreState = "none";

    @action
    async load(agentId: number) {
        this.state = "pending";

        try {
            const { data } = await domainApis.findDomains(agentId);
            this.domains = data.data;
            this.state = "done";
        } catch (e) {
            this.state = "error";
            throw e;
        }
    }

    @action
    clear() {
        this.domains = [];
        this.state = "none";
    }
}

export default DomainListStore;
