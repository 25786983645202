import React, { StrictMode } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import axios from "axios";
import { DISABLE_BUGSNAG, NLU_CTX } from "./variables";
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

axios.defaults.withCredentials = true;

Bugsnag.start({
    onError: () => {
        return !DISABLE_BUGSNAG;
    },
    apiKey: "86de7374abe2270cf12d23864fc26427",
    plugins: [new BugsnagPluginReact()],
    enabledReleaseStages: ["production"]
});

const ErrorBoundary = Bugsnag.getPlugin("react")!.createErrorBoundary(React);


ReactDOM.render(
    <ErrorBoundary>
        <StrictMode>
            <BrowserRouter basename={NLU_CTX || ""}>
                <App/>
            </BrowserRouter>
        </StrictMode>
    </ErrorBoundary>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
