import {action, observable} from 'mobx';
import {Intent, Sentence} from '../model/Domain';


export class SentenceAnswerModalStore {

    @observable
    sentences: Sentence[] = [];

    @observable
    sentenceAnswers: string[][] = [];

    @observable
    intent?: Intent | null = null;

    @observable
    focusIndex: FocusIndex = {sentence: 0, answer: 0};

    @observable
    openModal: boolean = false;

    @action
    setSentences(sentences: Sentence[]) {
        sentences = this.sentencesSort(sentences);
        this.sentences = sentences;
        this.sentenceAnswers = sentences.map(sentence => sentence.phrase_answers ? sentence.phrase_answers.concat('') : ['']);
    }

    @action
    updateAnswer(answer: string, sentenceIndex: number, answerIndex: number) {
        this.sentenceAnswers = this.sentenceAnswers.map((answers, index) => {
            if (index === sentenceIndex) {
                const changedAnswers = answers.map((el, index) => index === answerIndex ? answer : el);
                return changedAnswers.length - 1 === answerIndex ? [...changedAnswers, ''] : changedAnswers;
            }
            else {
                return answers;
            }
        });
    }

    @action
    getAnswer(sentenceIndex: number, answerIndex: number) {
        if (this.sentenceAnswers.length <= sentenceIndex) {
            throw new Error('Index out of bounds, sentence answers');
        }

        const answers = this.sentenceAnswers[sentenceIndex];

        if (answers.length <= answerIndex) {
            throw new Error('Index out of bounds, answers');
        }

        return answers[answerIndex];
    }

    @action
    deleteAnswer(sentenceIndex: number, answerIndex: number) {
        this.sentenceAnswers = this.sentenceAnswers.map((answers, index) => {
            if (index === sentenceIndex) {
                const deletedAnswers = answers.filter((_, index) => index !== answerIndex);
                return deletedAnswers.length > 0 ? deletedAnswers : [''];
            }
            else {
                return answers;
            }
        });
    }

    @action
    setFocusIndex(focusIndex: FocusIndex) {
        this.focusIndex = focusIndex;
    }

    @action
    open(intent: Intent) {
        this.intent = intent;
        this.openModal = true;
    }

    @action
    close() {
        this.sentences = [];
        this.intent = null;
        this.openModal = false;
    }

    @action
    sentencesSort(sentences: Sentence[]) {
        return sentences.map(sentence => this.answersSort(sentence))
            .sort((a, b) => {
            if (a.sentence > b.sentence) {
                return 1;
            } else if (a.sentence < b.sentence) {
                return -1;
            } else {
                return 0;
            }
        });
    }

    @action
    answersSort(sentence: Sentence) {
        return {...sentence, phrase_answers: sentence.phrase_answers?.sort()};
    }
}

type FocusIndex = {
    sentence: number,
    answer: number
}