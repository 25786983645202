import { action, observable } from "mobx";
import { EntityName } from "../model/Entity";
import { StoreState } from "../model/StoreState";
import * as entityApis from "../apis/entityApis";

export class EntityNameStore {
    @observable
    entityName?: EntityName;

    @observable
    state: StoreState = "none";

    @observable
    rejectedSynonyms: string | undefined = undefined;

    @action
    async load(agentId: number, domainId: number, entityNameId: string) {
        this.state = "pending";

        try {
            const { data } = await entityApis.readEntityName(agentId, domainId, entityNameId);
            this.entityName = data.data;
            this.state = "done";
        } catch (e) {
            this.state = "error";
            throw e;
        }
    }

    @action
    setRejectedSynonyms(query: string | undefined) {
        this.rejectedSynonyms = query;
    }

    @action
    clear() {
        this.entityName = undefined;
        this.state = "none";
    }
}
