import {action, observable} from "mobx";
import {Domain} from "../model/Domain";


class DomainTempStore {
    @observable
    domain?: Domain;

    @action
    setDomain(domain?: Domain) {
        this.domain = domain;
    }
}

export default DomainTempStore;