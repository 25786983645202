import {action, observable} from "mobx";


class SingleTestPanelStore {
    @observable
    isOpen: boolean = false;

    @action
    open() {
        this.isOpen = true;
    }

    @action
    close() {
        this.isOpen = false;
    }
}

export default SingleTestPanelStore;