

export type Page<T> = {
    total: number;
    total_pages: number;
    has_next: boolean;
    has_previous: boolean;
    next_page: boolean|null;
    previous_page: boolean|null;
    page: number;
    content: T[];
};

export const emptyPage = <T>() => {
    return {
        total: 0,
        total_pages: 0,
        has_next: false,
        has_previous: false,
        next_page: false,
        previous_page: false,
        page: 0,
        content: new Array<T>()
    }
};