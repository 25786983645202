import {action, observable} from "mobx";
import React from "react";


class DialogStore {

    @observable
    isOpen: boolean = false;

    @observable
    title?: string;

    @observable
    content?: string;

    @observable
    variant: DialogVariant = 'primary';

    @observable
    confirmText?: string;

    @observable
    cancelText?: string;

    @observable
    onConfirm: VoidFunction | null = null;

    @observable
    onCancel: VoidFunction | null = null;

    @action
    open(params: OpenParams) {
        this.isOpen = true;
        this.content = params.content;
        this.onConfirm = params.onConfirm;
        this.title = params.title;
        this.variant = params.variant || 'primary';
        this.confirmText = params.confirmText;
        this.cancelText = params.cancelText;
        this.onCancel = params.onCancel || null;
    }

    @action
    close() {
        this.isOpen = false;
        this.content = undefined;
        this.onConfirm = null;
        this.title = undefined;
        this.variant = 'primary';
        this.confirmText = undefined;
        this.cancelText = undefined;
        this.onCancel = null;
    }
}

type DialogVariant = 'primary' | 'info' | 'success' | 'warning' | 'danger';

type OpenParams = {content: string, onConfirm: VoidFunction, title?: string, variant?: DialogVariant, confirmText?: string, cancelText?: string, onCancel?: VoidFunction};

const dialogContext = React.createContext(new DialogStore());
export const useDialog = () => React.useContext(dialogContext);