import { action, observable } from 'mobx';
import { Intent } from '../model/Domain';


export class IntentStore {
    @observable
    intents: Intent[] = [];

    @observable
    selectedIntent?: Intent | null = null;

    @observable
    answerIntentId?: number;

    @action
    setIntents(intents: Intent[]) {
        this.intents = intents;
        // this.intents = this.intentSort(intents);
    }

    @action
    setAnswerIntentId(intentId: number | undefined) {
        this.answerIntentId = intentId
    }

    @action
    select(intent: Intent | null) {
        this.selectedIntent = intent;
    }

    @action
    intentSort(intentList: Intent[]) {
        return intentList.map(intent => {
            intent = this.sentencesSort(intent)
            intent = intent.answers ? this.answersSort(intent) : intent
            return intent
        })
    }

    @action
    answersSort(intent: Intent) {
        return {...intent, answers: intent.answers!.filter((answer) => {
                return !!answer
            }).sort()}
    }

    @action
    sentencesSort(intent: Intent) {
        return {...intent, sentences: intent.sentences?.sort((a, b) => {
            if (a.sentence > b.sentence) {
                return 1;
            } else if (a.sentence < b.sentence) {
                return -1;
            } else {
                return 0;
            }
        })}
    }
}