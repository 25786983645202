import {action, computed, observable} from 'mobx';


export class SlotTaggerStore {
    @observable
    slotTaggers: string[] = [];

    @action
    setSlotTaggers(slotTaggers: string[]) {
        this.slotTaggers = slotTaggers;
    }

    @computed
    get sharpSlots() {
        return this.slotTaggers.filter(slotTagger => slotTagger.indexOf('#') === 0);
    }

    @computed
    get atSlots() {
        return this.slotTaggers.filter(slotTagger => slotTagger.indexOf('@') === 0);
    }

    @action
    clear() {
        this.slotTaggers = [];
    }
}