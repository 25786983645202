import {action, observable} from "mobx";
import {Entity, EntityName} from "../model/Entity";


export class EntityStore {
    @observable
    entityName?: EntityName;

    @observable
    isOpenEditForm: boolean = false;

    @observable
    editingEntity?: Entity;

    @observable
    searchKeyword: string = '';

    @action
    setEntityName(entityName: EntityName) {
        this.entityName = entityName;
    }

    @action
    openEditForm(entity: Entity) {
        this.isOpenEditForm = true;
        this.editingEntity = entity;
    }

    @action
    closeEditForm() {
        this.isOpenEditForm = false;
        this.editingEntity = undefined;
    }

    @action
    setSearchKeyword(searchKeyword: string) {
        this.searchKeyword = searchKeyword;
    }

    @action
    clear() {
        this.entityName = undefined;
        this.editingEntity = undefined;
        this.searchKeyword = '';
        this.isOpenEditForm = false;
    }

}