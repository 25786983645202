import React, { useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { switchContent } from "./routers";
import "./static/css/global.css";
import Dialog from "./component/dialog/Dialog";
import Loading from "./component/loading/Loading";
import Notification from "./component/Notification";
import { DeleteDialog } from "./component/dialog/DeleteDialog";
import { observer } from "mobx-react-lite";
import { useStores } from "./hooks/useStores";
import { ErrorBoundary } from "./hooks/useErrorBoundary";
import AgentContextApp from "./AgentContextApp";
import PrivateRoute from "./component/PrivateRoute";
import * as view from "./view";
import ko from "./i18n/ko";
import en from "./i18n/en";
import { IntlProvider } from "react-intl";
import DictType from "./model/DictType";
import { API_HOST } from "./variables";

const App: React.FC = observer(() => {
    const { authenticationStore, languageSettingStore } = useStores();

    const messages: DictType = { ko: ko, en: en };

    useEffect(() => {
        authenticationStore
            .loadLoginStatus()
            .then(() => {
                if (!authenticationStore.isLogin) {
                    window.location.href = `${API_HOST}/v1/auth/login?client_redirect_uri=${document.URL}`;
                }
            })
            .catch((error) => {
                window.location.href = `${API_HOST}/v1/auth/login?client_redirect_uri=${document.URL}`;
                console.error(error);
            });
    }, [authenticationStore]);

    if (authenticationStore.isLogin) {
        return (
            <>
                <IntlProvider locale={languageSettingStore.language} messages={messages[languageSettingStore.language]}>
                    <ErrorBoundary>
                        <Switch>
                            <PrivateRoute exact path="/">
                                <Redirect to="/agents" />
                            </PrivateRoute>
                            {switchContent
                                .filter((route) => !route.hasMenu)
                                .map((route, index) => (
                                    <PrivateRoute key={`view-route-${index}`} exact path={route.path}>
                                        <route.component />
                                    </PrivateRoute>
                                ))}
                            <Route path={"/:agentId"}>
                                <AgentContextApp />
                            </Route>
                            <Route path={"*"}>
                                <view.notFoundRedirectView />
                            </Route>
                        </Switch>
                    </ErrorBoundary>
                    <Dialog />
                    <DeleteDialog />
                    <Loading />
                    <Notification />
                </IntlProvider>
            </>
        );
    } else {
        return null;
    }
});

export default App;
