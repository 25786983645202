import {action, observable} from "mobx";
import {TestCaseAnalysis, TestResult} from "../model/TestCase";
import {emptyPage, Page} from "../model/Page";

class SimulationStore {
    @observable
    analysisPage: Page<TestCaseAnalysis> = emptyPage();

    @observable
    testResult?: TestResult;

    @observable
    rank: 1|2 = 1;

    @observable
    keyword: string = "";

    @action
    setAnalysisPage(analysisPage: Page<TestCaseAnalysis>) {
        this.analysisPage = analysisPage;
    }

    @action
    setTestResult(testResult: TestResult) {
        if (testResult.distributions) {
            this.testResult = testResult;
        } else {
            this.testResult = undefined;
            throw TypeError;
        }
    }

    @action
    setRank(rank: 1|2) {
        this.rank = rank;
    }

    @action
    setKeyword(word: string) {
        this.keyword = word;
    }

    @action
    clear() {
        this.analysisPage = emptyPage();
        this.testResult = undefined;
        this.rank = 1;
        this.keyword = "";
    }
}


export default SimulationStore;